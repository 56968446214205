import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { SelectedTab } from '../views/SpeechToText';
import Tab from './Tab';

function Tabs({ children }) {
  const contextSelectedTab = useContext(SelectedTab);
  const [activeTab, setActiveTab] = useState(
    contextSelectedTab.selectedTranscription
  );
  const [tabList, setTabList] = useState(
    contextSelectedTab.selectedTranscription
  );

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setTabList(tab);
  };

  return (
    <SelectedTab.Consumer>
      {({ setSelectedTranscription }) => (
        <div className='tabs' style={{ textAlign: 'left' }}>
          <ol className='tab-list'>
            {children.map((child) => {
              const { label } = child.props;
              const { styleBorder } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={() => {
                    onClickTabItem(label);
                    setSelectedTranscription(label);
                  }}
                  tabList={tabList}
                  styleBorder={styleBorder}
                />
              );
            })}
          </ol>
          <div className='tab-content'>
            {children.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })}
          </div>
        </div>
      )}
    </SelectedTab.Consumer>
  );
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs;
