/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import { Navbar, Container } from 'reactstrap';
import { verifyAdmin } from 'models/Authentication';
import { useStoreToken } from '../Credentials/APIKeyModal';
import { useHistory } from 'react-router-dom';
import { clearCredential } from 'utils/Session';
import { GoogleLogout } from 'react-google-login';
import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';
import CustomPopover from 'components/CustomPopover/CustomPopover';

const PopUpContainer = styled.div`
  @media only screen and (max-width: 992px) {
    opacity: 0;
  }
`;

const RouteAdmin = () => {
  const [disabled, setDisabled] = useState(true);
  const token = useStoreToken((state) => state.token);
  const history = useHistory();

  useEffect(() => {
    if (token === '') return;
    const verifyUser = async () => {
      const result = await verifyAdmin(token);
      if (result !== false) {
        setDisabled(false);
      }
    };
    verifyUser();
  }, [token]);

  if (disabled) {
    return '';
  }

  const handleClickAdmin = () => {
    history.push('/admin');
  };

  return (
    <>
      <button onClick={handleClickAdmin} className='btn btn-success w-100'>
        Admin Page
      </button>
      <hr className='my-3' />
    </>
  );
};

const EmailComponent = () => {
  const email = useStoreToken((state) => state.email);
  return <>{email}</>;
};

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='lg' id='navbar-main'>
          <Container fluid>
            <Link
              className='h2 mb-0 text-white d-none d-lg-inline-block'
              to='/'
            >
              {this.props.headerTitle}
            </Link>
            {/* <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={require("assets/profile.jpg")}
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold text-white">
                    Demo User
                  </span>
                </Media>
              </Media> */}
            <PopUpContainer>
              <CustomPopover label={<EmailComponent />}>
                <Box minWidth='200px'>
                  <RouteAdmin />
                  <Box display='flex' width='100%'>
                    <GoogleLogout
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      buttonText='Logout'
                      render={(renderProps) => {
                        return (
                          <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            style={{ textTransform: 'none' }}
                          >
                            Logout
                            <i className='fa fa-sign-out-alt ml-2'></i>
                          </Button>
                        );
                      }}
                      onLogoutSuccess={() => {
                        clearCredential();
                        window.location.reload();
                      }}
                    ></GoogleLogout>
                  </Box>
                </Box>
              </CustomPopover>
            </PopUpContainer>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
