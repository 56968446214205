import React from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col, Button } from 'reactstrap';
import FlipMove from 'react-flip-move';

import Header from 'components/Headers/Header';
import Transcription from 'components/SpeechToText/Transcription';

import credentials from 'models/Credentials';
import transcriptionList from 'models/TranscriptionList';
import TranscriptionModel from 'models/Transcription';
import getIndex from 'requests/ASRIndex';
import getRealtimeIndex from 'requests/realtimeASRIndex';

export const SelectedTab = React.createContext();

class SpeechToTextView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelData: '',
      realtimeModelData: '',
      selectedTranscription: 'Non Real Time',
      single: null,
      interim: null,
      transcriptResult: null,
      audioResult: null,
    };
  }

  setSingle = (value) => {
    this.setState({
      single: value,
    });
  };

  setInterim = (value) => {
    this.setState({
      interim: value,
    });
  };

  setTranscriptResult = (value) => {
    this.setState({
      transcriptResult: value,
    });
  };

  setAudioResult = (value) => {
    this.setState({
      audioResult: value,
    });
  };

  componentDidMount() {
    const ASRModels = process.env.REACT_APP_ASR_MODELS;
    const realtimeASRModels = process.env.REACT_APP_ASR_ONLINE_MODELS;

    const getASRIndex = async () => {
      let result = await getIndex();
      let realtimeResult = await getRealtimeIndex();
      if (result.status === 'success' && realtimeResult.status === 'success') {
        let data = {};
        let realtimeData = {};

        if (ASRModels) {
          Object.keys(result.data).forEach((element) => {
            if (ASRModels.includes(element)) {
              data[element] = result.data[element];
            }
          });
        } else {
          data = result.data;
        }

        if (realtimeASRModels) {
          Object.keys(realtimeResult.data).forEach((element) => {
            if (realtimeASRModels.includes(element)) {
              realtimeData[element] = realtimeResult.data[element];
            }
          });
        } else {
          realtimeData = realtimeResult.data;
        }

        this.setState({
          modelData: data,
          realtimeModelData: realtimeData,
        });

        transcriptionList.data.forEach((element) => {
          element.setModel(Object.keys(data)[0]);
        });
      } else {
        let { status, error } = result;
        if (status === 'error_response') {
          if (('' + error.status)[0] === '4') {
            credentials.setInvalid('Invalid credential');
          } else {
            credentials.setInvalid('Credential validation failed');
          }
        } else if (status === 'error_request') {
          credentials.setInvalid('Credential validation failed');
        } else {
          credentials.setInvalid('Credential validation failed');
        }
      }
    };
    getASRIndex();
  }

  setSelectedTranscription = (value) => {
    this.setState({
      selectedTranscription: value,
    });
  };

  newTranscription = () => {
    let transcription = new TranscriptionModel('', '');
    let { modelData, realtimeModelData } = this.state;
    if (this.state.modelData) {
      transcription.setModel(Object.keys(modelData)[0]);
    }
    if (this.state.realtimeModelData) {
      transcription.setRealtimeModel(Object.keys(realtimeModelData)[0]);
    }
    transcriptionList.prepend(transcription);
    this.setState({
      single: null,
      interim: null,
      transcriptResult: null,
      audioResult: null,
    });
  };

  duplicateTranscription = (index) => {
    let transcription = transcriptionList.data[index];
    let duplication = transcription.duplicate();
    transcriptionList.insert(index, duplication);
  };

  removeTranscription = (index) => {
    transcriptionList.remove(index);
  };

  render() {
    let {
      modelData,
      realtimeModelData,
      selectedTranscription,
      single,
      interim,
      transcriptResult,
      audioResult,
    } = this.state;
    return (
      <>
        <Header />
        {credentials.isValid ? (
          <Container
            className='mt--9 pt-7 pt-md-5'
            fluid
            style={{ minHeight: '89vh' }}
          >
            <Row>
              <Col xl='12' className='d-flex flex-column mt-2'>
                <div className='text-right mb-4'>
                  <Button
                    type='button'
                    size='lg'
                    color='secondary'
                    onClick={this.newTranscription}
                  >
                    + Transkripsi
                  </Button>
                </div>
                <SelectedTab.Provider
                  value={{
                    selectedTranscription: selectedTranscription,
                    setSelectedTranscription: this.setSelectedTranscription,
                    single: single,
                    interim: interim,
                    transcriptResult: transcriptResult,
                    audioResult: audioResult,
                    setSingle: this.setSingle,
                    setInterim: this.setInterim,
                    setTranscriptResult: this.setTranscriptResult,
                    setAudioResult: this.setAudioResult,
                  }}
                >
                  <FlipMove duration={250}>
                    {transcriptionList.data.map((value, index) => (
                      <Transcription
                        transcription={value}
                        modelData={modelData}
                        realtimeModelData={realtimeModelData}
                        key={'transcription_' + value.key}
                        remove={() => this.removeTranscription(index)}
                        duplicate={() => this.duplicateTranscription(index)}
                        className='mb-4'
                      />
                    ))}
                  </FlipMove>
                </SelectedTab.Provider>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className='w-100' style={{ height: '72vh' }}></div>
        )}
      </>
    );
  }
}
export default observer(SpeechToTextView);
