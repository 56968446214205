import React from 'react';
import Sound from 'react-sound';
import Crunker from 'crunker';
import synthesize from 'requests/TTSSynthesize';
import Sentence from './Sentence';
import * as musicMetadata from 'music-metadata-browser';
import CustomSlider from 'components/CustomSlider/CustomSlider';
import 'css/tts.css';
import { Button, Row, Col, FormGroup, Alert, Input, Label } from 'reactstrap';

class TextToSpeech extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      isError: false,
      dataTransfer: false,
      idxSynth: -1,
      gender: 'male',
      urlSynth: '',
      execTime: 0,
      soundPlay: Sound.status.STOPPED,
      model: '',
      audioSrc: null,
      tempoAudio: 1,
      pitchAudio: 0,
      textInput: '',
      splittedText: [],
      startEndArray: [],
      audioBuffers: [],
      synthesizeStatus: [],
      onInput: true,
      playingTime: 0,
      onPlaying: false,
      errorMessage: 'Default Error Message',
      characters: 500,
      modelNameObject: {},
      modelNameObjectSelected: '',
      selectedModelNameList: [],
      selectedModelName: '',
    };

    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleChangeTempo = this.handleChangeTempo.bind(this);
    this.sleep = this.sleep.bind(this);
    this.audioRef = React.createRef();
    this.arrAudioName = [];
    this.textLength = 0;
  }

  componentDidMount() {
    let { modelData } = this.props;
    const modelName = {};
    if (Object.keys(modelData).length > 0) {
      this.setState({ model: Object.keys(modelData)[0] });
    }
    Object.keys(modelData).forEach((element) => {
      const { alias } = modelData[element];
      const arrayAlias = alias.split(' ');
      const actor = arrayAlias[0];
      if (!Object.keys(modelName).includes(actor)) {
        modelName[actor] = [];
      }
    });
    Object.keys(modelName).forEach((data) => {
      Object.keys(modelData).forEach((element) => {
        const { alias } = modelData[element];
        const arrayAlias = alias.split(' ');
        const actor = arrayAlias[0];
        modelData[element].key = element;
        if (actor === data) {
          modelName[actor].push(modelData[element]);
        }
      });
    });
    if (Object.keys(modelName).length > 0) {
      const selected = modelName[Object.keys(modelName)[0]];
      this.setState({
        modelNameObjectSelected: Object.keys(modelName)[0],
        selectedModelNameList: selected,
        selectedModelName: selected[0].key,
      });
    }
    this.setState({
      modelNameObject: modelName,
    });
  }

  onWSOpen = async (val) => {};

  errorMsg = async (msg) => {
    this.setState({ dataTransfer: false });
  };

  resetSynthesize = () => {
    this.setState({
      blob: '',
      startEndArray: [],
      audioBuffers: [],
      synthesizeStatus: [],
      playingTime: 0,
    });
  };

  raiseErrorMessage = (msg) => {
    this.setState({
      errorMessage: msg,
      isError: true,
      isLoading: false,
    });

    setTimeout(() => {
      this.setState({
        errorMessage: '',
        isError: false,
      });
    }, 3000);
  };

  synthesize = async () => {
    clearInterval(this.interval);
    let textArray = this.state.textInput.split('.');
    textArray = textArray.filter((element) => element !== '');
    this.resetSynthesize();
    this.setState({
      isLoading: true,
      onInput: false,
      splittedText: textArray,
    });
    let requests = await textArray.map((value) => {
      const synthesizeData = {
        text: value,
        pitch: (this.state.pitchAudio * 10).toString(),
        tempo: this.state.tempoAudio,
        model: this.state.selectedModelName,
      };
      return synthesize(synthesizeData);
    });

    let flagError = 0;
    let tempErrMsg = null;

    requests[0].then((data) => {
      if (data.status !== 'success' && data.error.status === 403) {
        this.raiseErrorMessage(
          'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
        );
      } else {
        Promise.all(requests)
          .then((data) => {
            let synthesizeStatus = [];
            let blobs = [];
            data.forEach((response) => {
              let blob;
              if (response.status === 'success') {
                synthesizeStatus.push('success');
                blob = new Blob([response.data], {
                  type: response.data.type,
                });
                blobs.push(blob);
              } else {
                synthesizeStatus.push(response.error.status);
                flagError = 1;
                if (tempErrMsg === null) {
                  tempErrMsg = response.error.status;
                }
              }
            });
            if (flagError === 0) {
              if (blobs.length === 0) {
              } else if (blobs.length === 1) {
                let blob = {
                  blob: blobs[0],
                  url: URL.createObjectURL(blobs[0]),
                };
                musicMetadata.parseBlob(blobs[0]).then((metadata) => {
                  let duration = metadata.format.duration;
                  this.setState(
                    {
                      synthesizeStatus: synthesizeStatus,
                      startEndArray: [{ start: 0, end: duration }],
                      blob: blob,
                    },
                    this.setUpAudio
                  );
                });
              } else {
                let blobArrayBuffers = blobs.map((blob) => blob.arrayBuffer());
                musicMetadata.parseBlob(blobs[0]).then((metadata) => {
                  let sampleRate = metadata.format.sampleRate;
                  Promise.all(blobArrayBuffers)
                    .then((buffers) => {
                      let audioBufferPromises = buffers.map((arrayBuffer) => {
                        var audioCtx =
                          new AudioContext({ sampleRate: sampleRate }) ||
                          window.webkitAudioContext({
                            sampleRate: sampleRate,
                          });
                        return audioCtx.decodeAudioData(arrayBuffer);
                      });
                      Promise.all(audioBufferPromises)
                        .then((audioBuffers) => {
                          let crunker = new Crunker({
                            sampleRate: sampleRate,
                          });
                          let startEndArray = [];
                          audioBuffers.forEach((buffer, index) => {
                            let start = index
                              ? startEndArray[index - 1].end
                              : 0;
                            let { duration } = buffer;
                            let end = start + duration;
                            let startEnd = {
                              start: start,
                              end: end,
                            };
                            startEndArray.push(startEnd);
                          });
                          let singleBuffer = crunker.concatAudio(audioBuffers);
                          let blob = crunker.export(
                            singleBuffer,
                            blobs[0].type
                          );
                          this.setState(
                            {
                              synthesizeStatus: synthesizeStatus,
                              startEndArray: startEndArray,
                              blob: blob,
                            },
                            this.setUpAudio
                          );
                        })
                        .catch((error) => {
                          this.raiseErrorMessage(error.message);
                        });
                    })
                    .catch((error) => {
                      this.raiseErrorMessage(error.message);
                    });
                });
              }
            } else {
              this.raiseErrorMessage(
                `Gateway Timeout Error (Code: ${tempErrMsg})`
              );
            }

            this.setState({
              isLoading: false,
              synthesizeStatus: synthesizeStatus,
            });
          })
          .catch((error) => {
            this.raiseErrorMessage(error.message);
          });
      }
    });
  };

  setUpAudio = () => {
    let audio = this.audioRef.current;
    audio.onended = () => {
      this.setState({
        playingTime: 0,
        onPlaying: false,
      });
      clearInterval(this.interval);
    };
    audio.onpause = () => {
      this.setState({
        onPlaying: false,
      });
      clearInterval(this.interval);
    };
    audio.onplaying = () => {
      clearInterval(this.interval);
      this.setState({ onPlaying: true });
      this.interval = setInterval(() => {
        this.setState({ playingTime: audio.currentTime });
      }, 200);
    };
    audio.play();
  };

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  setOnInput = () => {
    this.setState({
      blob: '',
      onInput: true,
    });
  };

  handleChangeModel(val) {
    const { value } = val.target;
    const listSelected = this.state.modelNameObject[value];
    const keySelected = listSelected[0].key;
    this.setState({
      modelNameObjectSelected: value,
      selectedModelNameList: listSelected,
      selectedModelName: keySelected,
    });
  }

  handleChangeTempo(val) {
    this.setState({ tempoAudio: val.target.value });
  }

  getAudioSrc() {
    const play_button = document.getElementById('audio_play_blob');
    if (play_button != null) {
      play_button.play();
    }
  }

  handleChangePitch = (event, newValue) => {
    this.setState({ pitchAudio: newValue });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const length = e.target.value.length;

    this.setState({
      textInput: e.target.value,
      characters: 500 - length,
    });
  };

  clearTextArea = (e) => {
    this.setState({
      textInput: '',
      splittedText: [],
      idxSynth: -1,
      execTime: 0,
      characters: 500,
    });
  };

  handleChangeStyle = (e) => {
    this.setState({
      selectedModelName: e.target.value,
    });
  };

  render() {
    let statusButton = false;
    if (
      this.state.isLoading === true ||
      this.state.textInput === '' ||
      this.state.characters < 0
    ) {
      statusButton = true;
    }
    const {
      modelNameObject,
      selectedModelNameList,
      selectedModelName,
    } = this.state;
    return (
      <Col>
        <Row>
          <Col xs='12' md='4' xl='2'>
            <FormGroup controlid='select-gender'>
              <Label for='model-input' className='mb-3'>
                Model
              </Label>
              <Input
                id='model-input'
                className='form-control-model'
                type='select'
                value={this.state.modelNameObjectSelected}
                onChange={this.handleChangeModel}
              >
                {Object.keys(modelNameObject).map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xs='12' md='4' xl='2'>
            <FormGroup controlid='select-gender'>
              <Label for='prosidy-input' className='mb-3'>
                Gaya Bicara
              </Label>
              <Input
                id='prosidy-input'
                className='form-control-model'
                type='select'
                value={selectedModelName}
                onChange={this.handleChangeStyle}
              >
                {selectedModelNameList.map((element, index) => {
                  return (
                    <option key={index} value={element.key}>
                      {element.prosody}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          <Col xs='12' md='4' xl='2'>
            <FormGroup controlid='select-gender'>
              <Label for='speed-input' className='mb-3'>
                Kecepatan
              </Label>
              <Input
                id='speed-input'
                className='form-control-model'
                type='select'
                placeholder='Pilih salah satu'
                value={this.state.tempoAudio}
                onChange={this.handleChangeTempo}
              >
                <option value='0.25'>0.25</option>
                <option value='0.5'>0.5</option>
                <option value='0.75'>0.75</option>
                <option value='1'>Normal</option>
                <option value='1.25'>1.25</option>
                <option value='1.5'>1.5</option>
                <option value='1.75'>1.75</option>
                <option value='2'>2</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs='12' xl='6'>
            <FormGroup>
              <Label for='pitch-input'>Tinggi Nada</Label>
              <div className='mt-2'>
                <CustomSlider
                  value={this.state.pitchAudio}
                  setValue={(newValue) => {
                    this.setState({
                      pitchAudio: newValue,
                    });
                  }}
                  disabled={false}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              {this.state.onInput ? (
                <Input
                  rows='10'
                  type='textarea'
                  name='text'
                  placeholder='Teks masukan'
                  value={this.state.textInput}
                  style={{ backgroundColor: '#f8f9fe' }}
                  className='form-control-alternative'
                  onChange={this.handleInputChange.bind(this)}
                />
              ) : (
                <div className='p-2'>
                  {this.state.splittedText.map((element, index) => {
                    let highlighted = false;
                    let { startEndArray, playingTime } = this.state;
                    if (playingTime && startEndArray.length > index) {
                      if (
                        playingTime >= startEndArray[index].start &&
                        playingTime <= startEndArray[index].end
                      ) {
                        highlighted = true;
                      }
                    }
                    return (
                      <Sentence
                        text={element}
                        highlighted={highlighted}
                        key={'sentence-' + index}
                      />
                    );
                  })}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            {this.state.execTime !== 0 && (
              <p>Execution Time: {this.state.execTime} milliseconds</p>
            )}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col
            xs='12'
            className={this.state.characters < 0 ? 'text-danger' : ''}
          >
            <div>Characters Remaining: {this.state.characters}</div>
          </Col>
        </Row>
        <Row>
          <Col xs='12' md='3' lg='3' xl='2' className='form-group'>
            <Button
              color='default'
              className='btn-icon btn-2 mr-2 btn-block'
              onClick={this.synthesize}
              disabled={statusButton}
            >
              Sintesis
              {this.state.isLoading && (
                <span className='ml-2'>
                  <i
                    className='fa fa-circle-notch fa-spin text-white'
                    style={{ fontSize: '16px' }}
                  ></i>
                </span>
              )}
            </Button>
          </Col>
          <Col xs='12' md='6' lg='6' xl='4' className='form-group'>
            {this.state.blob && (
              <audio
                id='audio_play_blob'
                src={this.state.blob.url}
                style={{ height: '40px' }}
                className='form-group preview-audio w-100'
                controls
                ref={this.audioRef}
              />
            )}
          </Col>
          <Col
            xs='12'
            md='3'
            lg={{ size: '3' }}
            xl={{ offset: '4', size: '2' }}
            className='form-group text-right'
          >
            <Button
              color='secondary'
              className='btn-icon btn-2 mr-2'
              onClick={this.setOnInput}
              disabled={
                this.state.isLoading ||
                this.state.onInput ||
                this.state.onPlaying
              }
            >
              <i className='fa fa-edit text-dark'></i>
            </Button>
            <Button
              color='secondary'
              className='btn-icon btn-2 mr-2'
              onClick={this.clearTextArea.bind(this)}
              disabled={this.state.isLoading || !this.state.textInput}
            >
              <i className='fa fa-eraser text-danger'></i>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert color='danger' isOpen={this.state.isError}>
              {this.state.errorMessage}
            </Alert>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default TextToSpeech;
