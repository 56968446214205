import { observable, autorun, action } from 'mobx';
import Transcription from './Transcription'

let transcriptionList = observable({
    data: [],

    get log() {
        return this.data.map(element => element.log)
    },

    insert(index, item) {
        this.data.splice(index, 0, item);
    },
    setData(data) {
        this.data = data
    },
    remove(index) {
        this.data.splice(index, 1);
    },
    move(from, to) {
        let item = this.data[from]
        if (from < to) {
            this.insertTo(to, item)
            this.splice(from)
        } else {
            this.splice(from, 1)
            this.insertTo(to, item)
        }
    },
    prepend(item) {
        this.data.splice(0, 0, item)
    },
    append(item) {
        this.data.push(item)
    }
},
    {
        insert: action,
        remove: action,
        move: action,
        prepend: action,
        setData: action,
    })

autorun(() => console.table(transcriptionList.log))
let transcription = new Transcription('','')
transcriptionList.prepend(transcription)

export default transcriptionList;