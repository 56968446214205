import React from 'react';
import { observer } from 'mobx-react';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Header from 'components/Headers/Header';
import credentials from 'models/Credentials';
import Recognition from 'components/SpeakerBiometric/Recognition';
import Enrolment from 'components/SpeakerBiometric/Enrolment';
import TabsSid from 'components/TabsSid';
import 'css/biometric.css';

class SpeakerBiometricView extends React.PureComponent {
  render() {
    const styleCursor = {
      cursor: 'pointer',
    };
    return (
      <>
        <Header />
        {credentials.isValid ? (
          <Container className='mt--9 pt-7' fluid style={{ minHeight: '89vh' }}>
            <Row>
              <Col xl='12'>
                <Card className='shadow'>
                  <CardBody>
                    {/* audio transcription */}
                    <Row>
                      <div className='body-wrapper' style={{ width: '100%' }}>
                        <div className='content-wrapper'>
                          {/* <h1 className="mainTitle">Speaker Biometric System</h1> */}
                          <TabsSid>
                            <div label='Pengenalan' styleBorder={styleCursor}>
                              <Col>
                                <p className='description'>
                                  Halaman ini digunakan untuk mengenali
                                  identitas pemilik suara (identifikasi) maupun
                                  mengonfirmasi identitas pemilik suara
                                  (verifikasi). Lakukan pendaftaran suara
                                  terlebih dahulu jika suara Anda belum pernah
                                  didaftarkan.
                                </p>
                                {/*<p className="description">
                                  Sistem ini melakukan verifikasi pemilik suara berdasarkan unggahan atau rekaman langsung oleh pengguna. Rekaman pertama digunakan untuk mendaftarkan <i>voiceprint</i>. Rekaman selanjutnya digunakan sebagai verifikasi. Silahkan melakukan rekaman dan pastikan mikrofonmu dalam keadaan aktif. Selamat mencoba...
                                  Silakan ucapkan kata-kata dalam Bahasa Indonesia dan pastikan mikrofonmu dalam keadaan aktif atau dengan mengupload file audio. Selamat mencoba...
                                </p>*/}
                                <Recognition />
                              </Col>
                            </div>
                            <div label='Pendaftaran' styleBorder={styleCursor}>
                              <Col>
                                <p className='description'>
                                  Silahkan daftarkan id dan jenis kelamin untuk
                                  pengguna baru, anda bisa mengubah data
                                  pengguna yang terdaftar dengan mendaftarkan
                                  kembali sidik suara untuk id yang telah
                                  terdaftar, anda juga dapat menghapus data
                                  pengguna yang telah terdaftar
                                </p>
                                <Enrolment />
                              </Col>
                            </div>
                          </TabsSid>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className='w-100' style={{ height: '72vh' }}></div>
        )}
      </>
    );
  }
}

export default observer(SpeakerBiometricView);
