import axios from 'axios'
import config from 'config'

import credentials from 'models/Credentials'

export default async function getIndex() {
  const { origin, version, basename } = config.api.indexOnlineAsr
  const url = `${origin}/${version}/${basename}/index`
  let result = {}
  await axios({
    method: 'get',
    url: url,
    headers:{
      'Content-type': 'application/json; charset=UTF-8',
      'X-Api-Key': credentials.APIKey,
    },
  }).then(response => {
    result = {
      status: 'success',
      data: response.data
    }
    console.log('asr_online_index', result)
  }).catch(error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      result = {
        status: 'error_response',
        error: {
          data : error.response.data,
          status: error.response.status,
          headers: error.response.headers
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      result = {
        status: 'error_request',
        error: error.request
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      result = {
        status: 'error',
        error: error.message
      }
    }
    console.log(error.config);
  });
  return {
    status: 'success',
    data: {
      "general": {
          "label": "General",
          "samplerate": 16000,
          "domain": "General",
          "language": "Bahasa Indonesia",
          "acoustic": "Microphone"
      },
      "law-political": {
          "label": "Law-Political",
          "samplerate": 16000,
          "domain": "Law & Political Conversation",
          "language": "Bahasa Indonesia",
          "acoustic": "Microphone"
      },
      "telecom": {
          "label": "Telecommunication",
          "samplerate": 8000,
          "domain": "Phone Call Conversation (CS & Client)",
          "language": "Bahasa Indonesia",
          "acoustic": "Phone"
      }
    }
  }
}