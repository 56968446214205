import Axios, { AxiosRequestConfig } from 'axios';
import urlConfig from 'config';

type AuthenticateData = {
  code: number;
};

const { origin, basename } = urlConfig.api.auth;

export const authenticate = async (token: string) => {
  try {
    const data = {
      token,
    };
    const request = await Axios.post(`${origin}/${basename}/login`, data);
    const result: AuthenticateData = request.data;
    return result;
  } catch (error) {
    if (error.response === undefined) return 'Connection Error';
    return error.response.statusText;
  }
};

export const registerUser = async (
  organization: string,
  phone_number: string,
  token: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      organization,
      phone_number,
    };
    const request = await Axios.post(
      `${origin}/${basename}/register`,
      data,
      config
    );
    const result = request.data;
    return result;
  } catch (error) {
    return false;
  }
};

export const verifyAdmin = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data: result } = await Axios.get(
      `${origin}/${basename}/elevate`,
      config
    );
    return result;
  } catch (error) {
    return false;
  }
};
