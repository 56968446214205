import React from "react";
import { Input, Col } from 'reactstrap';

function TranscriptList({ transcript, ...props }) {
  return (
    <>
      <div className="mb-2">
        Transkrip
      </div>
      <div>
        <Col className="mx-0 px-0 pr-xl-2" {...props}>
          <Input
            disabled
            rows="3"
            type="textarea"
            value={transcript.text}
            style={{ backgroundColor: '#f8f9fe' }}
            className="form-control-alternative"
          />
        </Col>
      </div>
    </>
  );
}

export default TranscriptList;