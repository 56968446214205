import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

export default function CustomDataGrid({ columns, rows }) {
  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        showToolbar
        autoPageSize
        disableSelectionOnClick
      />
    </div>
  );
}
