import { Component } from "react";
import Dropzone from 'react-dropzone'
import React from "react";
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Howl } from 'howler';

class Uploader extends Component {
  constructor(props) {
    super();
    this.state = {
      accepted: [],
      rejected: [],
      text: "",
      audioBlob: "",
      filename: "",
    }
  }

  onPreviewDrop = (files) => {
    let { maxDuration, maxSize } = this.props
    console.log('file accepted ', files, files.length)
    if (files.length < 1) {
      this.props.onRejectFile('Uploaded audio is invalid')
    } else if (files.length > 1) {
      this.props.onRejectFile('Only one audio file allowed')
    } else {
      let component = this;
      let size = files[0].size
      console.log('file size:', size)
      if (size <= 0) {
        this.props.onRejectFile('File is empty')
      } else if(size>=maxSize*1000000) {
        this.props.onRejectFile(`File size can't be more than ${maxSize} MB`)
      } else {
        new Howl({
          format: ['wav', 'webm', 'mp3', 'opus', 'mp4', 'weba', 'opus'],
          src: [files[0].preview],
          onload: function () {
            let duration = this.duration()
            if (maxDuration && duration > maxDuration) {
              component.props.onRejectFile(`Media duration can't be more than ${maxDuration} s`)
            } else if (duration === 0) {
              component.props.onRejectFile('Uploaded audio is invalid')
            } else {
              console.log(typeof (files[0]))
              component.props.onAcceptFile(files[0])
            }
          },
        })
      }
    }
  }

  render() {
    return (
      <Dropzone
        multiple={false}
        style={dropzoneStyle}
        accept="audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, video/mp4, .mp3, .opus, .webm, .weba"
        disabled={this.props.disabled}
        onDrop={this.onPreviewDrop}
      >
        <Button
          size="md"
          type="button"
          className="btn-icon btn-2 mr-2 btn-block"
          color="default"
          id="upload-btn"
          style={{ fontSize: '1rem' }}
          disabled={this.props.disabled}
        >
          <i className="fas fa-upload"></i>
        </Button>
        <UncontrolledTooltip
          trigger="hover"
          placement="top"
          target="upload-btn"
        >
          Unggah
        </UncontrolledTooltip>
      </Dropzone>
    );
  }
}

const dropzoneStyle = {
  width: '100%',
};

export default Uploader
