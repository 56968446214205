import React, { Component } from "react"
import {
  Row,
  Col,
  Input,
} from "reactstrap";
import Identification from 'components/SpeakerBiometric/Identification'
import Verification from 'components/SpeakerBiometric/Verification'

class Recognition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recognition_mode: "identification"
    }

    this.handleChangeMode = this.handleChangeMode.bind(this)
  }

  handleChangeMode(val){
    this.setState({ recognition_mode: val.target.value })
  }

  render() {
    return (
      <>
        <Row style={{marginBottom: "15px"}}>
          <Col xs="12" md="4" xl="4">
            <label style={{fontWeight: "600", marginBottom: "20px"}}> Mode Pengenalan: </label>
            <form className="form-group" style={{display: "flex"}}>
              <Col md="6">
                <Row>
                  <Input
                    type="select"
                    name="domain"
                    id="domain-input"
                    onChange={this.handleChangeMode}
                  >
                    <option value="identification" key="identification">
                      Identifikasi
                    </option>
                    <option value="verification" key="verification">
                      Verifikasi
                    </option>
                  </Input>
                </Row>
              </Col>
            </form>
          </Col>
          {
            this.state.recognition_mode === "identification"? <Identification/> : <Verification/>
          }
        </Row>
      </>       
    );
  }
}

export default Recognition;