import React, { useState, useEffect, useContext } from 'react';
import {
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Alert,
  Button,
  UncontrolledTooltip,
  CardFooter,
} from 'reactstrap';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import RecordingAudioRealTime from '../RecordingAudioRealTime';
import CustomAudioPlayer from '../CustomAudioPlayer';
import { SelectedTab } from '../../views/SpeechToText';
import { ffmpegConvertToWav } from 'utils/Converter';
import ScaleLoader from 'react-spinners/ScaleLoader';

function RealTimeTranscription({
  transcription,
  realtimeModelData,
  remove,
  duplicate,
}) {
  const contextSelectedTab = useContext(SelectedTab);
  const [model, setModel] = useState(
    transcription.realtimeModel === ''
      ? Object.keys(realtimeModelData)[0]
      : transcription.realtimeModel
  );
  const [resultTranscription, setResultTranscription] = useState(
    contextSelectedTab.transcriptResult
  );
  const [audioFile, setAudioFile] = useState(contextSelectedTab.audioResult);
  const [single, setSingle] = useState(contextSelectedTab.single);
  const [interim, setInterim] = useState(contextSelectedTab.interim);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('Not Started');
  const [isEncoding, setIsEncoding] = useState(false);

  const saveTranscriptResult = async () => {
    let filename = 'Transcription_result.zip';
    var zip = new JSZip();
    zip.file('transcript.txt', resultTranscription);
    let blob = await fetch(audioFile).then((r) => r.blob());
    zip.file('audio.wav', blob);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, filename);
    });
  };

  const setTranscript = (transcript) => {
    setResultTranscription(transcript);
    contextSelectedTab.setTranscriptResult(transcript);
  };

  const setAudio = async (audio) => {
    if (audio === null) return;
    setIsEncoding(true);
    const blob = new Blob(audio, { type: 'audio/webm' });
    const data = await ffmpegConvertToWav(blob);
    setAudioFile(
      URL.createObjectURL(new Blob([data.buffer], { type: 'audio/wav' }))
    );
    contextSelectedTab.setAudioResult(
      URL.createObjectURL(new Blob([data.buffer], { type: 'audio/wav' }))
    );
    setIsEncoding(false);
  };

  const setSingleUtterance = (value) => {
    setSingle(value);
    contextSelectedTab.setSingle(value);
  };

  const setInterimResult = (value) => {
    setInterim(value);
    contextSelectedTab.setInterim(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(null);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  return (
    <SelectedTab.Consumer>
      {({ setTranscriptResult, setAudioResult, setSingle, setInterim }) => (
        <React.Fragment>
          <CardBody>
            <Row>
              <Col xs='12' md='4' xl='5' className='mb-4 mb-md-0'>
                <Row className='mx-0 mb-3 mb-xl-0'>
                  <Col sm='12' xl='6' className='px-0 ml-0 pr-0 pr-xl-4'>
                    <div className='mb-2'>Model</div>
                    <FormGroup>
                      <Input
                        type='select'
                        name='domain'
                        value={model}
                        disabled={status === 'Processing' ? true : false}
                        onChange={(e) => {
                          transcription.setRealtimeModel(e.target.value);
                          setModel(e.target.value);
                        }}
                        id='domain-input'
                      >
                        {Object.keys(realtimeModelData).map((item) => (
                          <option value={item} key={item}>
                            {realtimeModelData[item].label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className='px-0'>
                    <div className='mb-2'>Audio</div>
                    <Row className='mx-0 justify-content-between'>
                      <Col xs='12' className='pl-0 pr-0 pr-md-3 mb-2 mb-md-0'>
                        <RecordingAudioRealTime
                          model={model}
                          setResultTranscription={setTranscript}
                          setAudioFile={setAudio}
                          setError={setError}
                          setStatus={setStatus}
                          status={status}
                          single={single}
                          interim={interim}
                          setSingle={setSingleUtterance}
                          setInterim={setInterimResult}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <div className='mb-2'>
                      Transkripsi{' '}
                      <span
                        className={
                          status === 'Not Started'
                            ? 'text-info'
                            : status === 'Processing'
                            ? 'text-warning'
                            : status === 'Error'
                            ? 'text-danger'
                            : 'text-success'
                        }
                      >
                        ({status})
                      </span>
                    </div>
                    <Col className='mx-0 px-0 pr-xl-2'>
                      <textarea
                        disabled
                        rows='5'
                        className='form-control-alternative w-100 p-2'
                        value={
                          resultTranscription === null
                            ? ''
                            : resultTranscription
                        }
                      ></textarea>
                    </Col>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    {!isEncoding ? (
                      audioFile !== null ? (
                        <CustomAudioPlayer src={audioFile} />
                      ) : (
                        ''
                      )
                    ) : (
                      <Col>
                        <div className='d-flex justify-content-center'>
                          <ScaleLoader
                            height={35}
                            width={4}
                            radius={2}
                            margin={2}
                          />
                        </div>{' '}
                      </Col>
                    )}
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Alert
                      color='danger'
                      isOpen={error === null ? false : true}
                    >
                      {error}
                    </Alert>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className='text-right'>
            {audioFile && (
              <>
                <Button
                  color='secondary'
                  onClick={saveTranscriptResult}
                  className='btn-icon btn-2'
                  id='download-btn'
                  type='button'
                >
                  <i className='fas fa-download text-default'></i>
                </Button>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target='download-btn'
                >
                  Unduh
                </UncontrolledTooltip>
              </>
            )}
            <Button
              type='button'
              color='secondary'
              className='btn-icon btn-2'
              onClick={() => {
                duplicate();
                setTranscriptResult(resultTranscription);
                setAudioResult(audioFile);
                setSingle(single);
                setInterim(interim);
              }}
              id='duplicate-btn'
            >
              <i className='fa fa-clone'></i>
            </Button>
            <UncontrolledTooltip
              trigger='hover'
              placement='top'
              target='duplicate-btn'
            >
              Duplikasi
            </UncontrolledTooltip>
            <Button
              type='button'
              color='secondary'
              className='btn-icon bnt-2'
              onClick={remove}
              id='remove-btn'
            >
              <i className='fa fa-trash text-danger'></i>
            </Button>
            <UncontrolledTooltip
              trigger='hover'
              placement='top'
              target='remove-btn'
            >
              Hapus
            </UncontrolledTooltip>
          </CardFooter>
        </React.Fragment>
      )}
    </SelectedTab.Consumer>
  );
}

export default RealTimeTranscription;
