import SpeechToText from 'views/SpeechToText';
import TextToSpeech from 'views/TextToSpeech';
import SpeakerBiometric from 'views/SpeakerBiometric';

var routes = [
  {
    id: 'asr',
    path: "speech-to-text",
    name: "Speech To Text",
    icon: "fas fa-file-alt text-green",
    component: SpeechToText,
    layout: "/",
  },
  {
    id: 'tts',
    path: "text-to-speech",
    name: "Text To Speech",
    icon: "fas fa-volume-up text-blue",
    component: TextToSpeech,
    layout: "/",
  },
  {
    id: 'sid',
    path: "speaker-biometric",
    name: "Speaker Biometric",
    icon: "fas fa-fingerprint text-orange",
    component: SpeakerBiometric,
    layout: "/",
  },
  // {
  //   path: "sample",
  //   name: "Sample Page",
  //   icon: "far fa-file-alt text-primary",
  //   component: Sample,
  //   layout: "/",
  //   enabled: false,
  // },
];

export default routes;
