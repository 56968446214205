import GoogleLogin from 'react-google-login';
import credentials from 'models/Credentials';
import getIndex from 'requests/ASRIndex';
import Register from './Register';
import create from 'zustand';
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Card, CardBody, CardHeader, Alert } from 'reactstrap';
import { observer } from 'mobx-react';
import { authenticate, registerUser } from 'models/Authentication';
import { clearCredential, getCredential, saveCredential } from 'utils/Session';

const REGISTERED = 1;
const NOT_REGISTERED = 2;

export const useStoreToken = create((set) => ({
  email: '',
  token: '',
  setEmail: (email) =>
    set(() => ({
      email,
    })),
  setToken: (token) =>
    set(() => ({
      token,
    })),
  setData: (token, email) =>
    set(() => ({
      token,
      email,
    })),
}));

function APIKeyModal() {
  const [login, setLogin] = useState(true);
  const [error, setError] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitRegister, setIsSubmitRegister] = useState(false);
  const setData = useStoreToken((state) => state.setData);
  const textInput = useRef(null);
  const userData = useRef({
    idToken: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    setTimeout(function () {
      if (textInput.current) {
        textInput.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    const sessionData = getCredential();
    if (sessionData.googleToken === undefined) return;
    if (sessionData.googleToken === '' || sessionData.apiKey === '') return;
    setData(sessionData.googleToken, sessionData.email);
    credentials.setAPIKey(sessionData.apiKey);
    const validation = async () => {
      const result = await getIndex();
      if (result.status === 'success') {
        credentials.setValid();
      } else {
        clearCredential();
      }
    };
    validation();
  }, [setData]);

  async function validateCredentials() {
    credentials.setValidating();
    let result = await getIndex();
    if (result.status === 'success') {
      const sessionData = getCredential();
      sessionData.apiKey = credentials.APIKey;
      sessionData.email = userData.current.email;
      saveCredential(sessionData);
      credentials.setValid();
    } else {
      let { status, error } = result;
      if (status === 'error_response') {
        if (error.status === 403) {
          setError(
            'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
          );
          credentials.setInvalid(
            'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
          );
        } else {
          if (('' + error.status)[0] === '4') {
            setError('Invalid credential');
            credentials.setInvalid('Invalid credential');
          } else {
            setError('Credential validation failed');
            credentials.setInvalid('Credential validation failed');
          }
        }
      } else if (status === 'error_request') {
        credentials.setInvalid('Credential validation failed');
        setError('Credential validation failed');
      } else {
        credentials.setInvalid('Credential validation failed');
        setError('Credential validation failed');
      }
    }
    setIsSubmit(false);
  }

  const validateResult = async (result) => {
    if (typeof result === 'string') {
      setError(result);
    } else {
      if (result.code === NOT_REGISTERED) {
        setLogin(false);
      } else if (result.code === REGISTERED) {
        const { data } = result;
        const apiKey = data['api-key'];
        credentials.setAPIKey(apiKey);
        validateCredentials();
      }
    }
  };

  const responseGoogle = async (response) => {
    setIsSubmit(true);
    const { profileObj, tokenId } = response;
    userData.current.idToken = tokenId;
    userData.current.email = profileObj.email;
    userData.current.firstName = profileObj.givenName;
    userData.current.lastName = profileObj.familyName;
    userData.current.username = profileObj.email.split('@')[0];
    setData(tokenId, profileObj.email);
    const sessionData = getCredential();
    sessionData.googleToken = tokenId;
    sessionData.email = profileObj.email;
    saveCredential(sessionData);
    const result = await authenticate(tokenId);
    validateResult(result);
  };

  const responseGoogleError = async (response) => {
    console.log(response);
  };

  const registerData = async (organization, phone) => {
    if (organization === '') {
      setError('Fill Organization');
      return;
    }
    if (phone === '') {
      setError('Fill Phone Number');
      return;
    }
    setIsSubmitRegister(true);
    const result = await registerUser(
      organization,
      phone,
      userData.current.idToken
    );
    if (result === false) {
      setError('Registration Error');
      setIsSubmit(false);
      setIsSubmitRegister(false);
      return;
    }
    const resultAuthentication = await authenticate(userData.current.idToken);
    validateResult(resultAuthentication);
    setIsSubmitRegister(false);
  };

  return (
    <Modal
      className='modal-dialog-centered'
      size='md'
      isOpen={!credentials.isValid}
    >
      <div className='modal-body p-0'>
        <Card className='bg-secondary shadow border-0'>
          <CardHeader className='bg-transparent'>
            <div className='text-center mt-1 mb-1'>
              {login ? (
                'Sign In with Google'
              ) : (
                <>
                  You have not registered yet. <br /> Fill this form to
                  register.
                </>
              )}
            </div>
          </CardHeader>
          {login ? (
            <CardBody className='px-lg-5 py-lg-4 d-flex justify-content-center align-items-center'>
              {isSubmit ? (
                <span>
                  <i
                    className='fa fa-circle-notch fa-spin text-dark'
                    style={{ fontSize: '24px' }}
                  ></i>
                </span>
              ) : (
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText='Sign In'
                  onSuccess={responseGoogle}
                  onFailure={responseGoogleError}
                  cookiePolicy={'single_host_origin'}
                />
              )}
            </CardBody>
          ) : (
            <CardBody className='px-lg-5 py-lg-4 '>
              <Register
                registerData={registerData}
                isSubmitRegister={isSubmitRegister}
              />
            </CardBody>
          )}
          {error ? <Alert color='warning'>{error}</Alert> : ''}
        </Card>
      </div>
    </Modal>
  );
}

export default observer(APIKeyModal);
