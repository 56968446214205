module.exports = {
  api: {
    asr: {
      name: '',
      version: 'v1',
      basename: 'speech/asr',
      origin: 'https://api.prosa.ai',
      ws: false,
    },
    tts: {
      name: 'tts',
      version: 'v1',
      basename: 'speech/tts',
      origin: 'https://api.prosa.ai',
      ws: false,
    },
    sid: {
      name: 'sid',
      version: 'v1',
      basename: 'sid',
      origin: 'http://35.187.243.117:8080',
      ws: false,
    },
    ws: {
      name: 'ws',
      version: 'v1',
      basename: 'asr',
      origin: 'asr-streaming.prosa.ai',
      ws: true,
    },
    indexOnlineAsr: {
      name: 'indexOnlineAsr',
      version: 'v1',
      basename: 'asr-online',
      origin: 'https://api.speech.prosa.ai',
      ws: false,
    },
    realtimeAsr: {
      name: 'ws',
      version: 'v1',
      basename: 'asr-online',
      origin: 'http://asr-streaming.prosa.ai',
      ws: false,
    },
    auth: {
      name: 'auth',
      version: '',
      basename: 'auth',
      origin:
        process.env.NODE_ENV === 'production'
          ? `https://speech-demo.prosa.ai`
          : `https://speechdemo-acc.prosa.ai`,
      ws: false,
    },
  },
};
