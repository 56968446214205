import React, { useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { downloadObjectUrl } from '../utils/Converter';

const StyledAudioPlayer = styled(AudioPlayer)`
  background-color: transparent;
  box-shadow: none;
  border: none;
`;

const StyledIconContainer = styled.span`
  font-size: 1.4rem;
`;

const StyledIcon = styled.i`
  &:hover {
    cursor: pointer;
  }
`;

function CustomAudioPlayer({ src }) {
  const downloadBlob = async () => {
    downloadObjectUrl(src);
  };

  useEffect(() => {
    let playerContainer = document.querySelector('.rhap_additional-controls');
    let audioSlider = document.querySelector('.rhap_progress-section');
    document.querySelector('.rhap_volume-container').style.display = 'none';
    if (playerContainer !== null) {
      playerContainer.className = '';
    }
    if (audioSlider !== null) {
      audioSlider.className = 'rhap_progress-section w-100';
    }
  }, [src]);

  return (
    <React.Fragment>
      <div className='d-flex'>
        <StyledAudioPlayer
          autoPlayAfterSrcChange={false}
          customAdditionalControls={[]}
          src={src}
          showJumpControls={false}
          layout='horizontal-reverse'
        />
        <StyledIconContainer
          className='color-test d-flex align-items-center'
          onClick={downloadBlob}
        >
          <StyledIcon
            id='icon-download'
            className='fa fa-download mr-2'
          ></StyledIcon>
          <UncontrolledTooltip
            trigger='hover'
            placement='top'
            target='icon-download'
          >
            Download Audio
          </UncontrolledTooltip>
        </StyledIconContainer>
      </div>
    </React.Fragment>
  );
}

export default CustomAudioPlayer;
