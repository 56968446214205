import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

class TabsSid extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    const title = this.props;
    const styleBorder = this.props;
    this.state = {
      activeTab: title.children[0].props.label,
      tabList: styleBorder.children[0].props.styleBorder,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab, tabList: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab, tabList },
    } = this;

    return (
      <div className='tabs' style={{ textAlign: 'left' }}>
        {/* <ol className="tab-list" style={{ display: 'inline-block' }}> */}
        <ol className='tab-list'>
          {children.map((child) => {
            const { label } = child.props;
            const { styleBorder } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                tabList={tabList}
                styleBorder={styleBorder}
              />
            );
          })}
        </ol>
        <div className='tab-content'>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default TabsSid;
