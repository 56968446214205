import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

function SimpleDialog({ onClose, open, title, description }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      hideBackdrop
    >
      <Box p={3}>
        <DialogTitle id='simple-dialog-title'>{description}</DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='center' mt={1}>
          <Button variant='contained' onClick={handleClose}>
            Tutup
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default function CustomDialog({ open, setOpen, title, description }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SimpleDialog
      open={open}
      onClose={handleClose}
      title={title}
      description={description}
    />
  );
}

CustomDialog.prototype = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
};
