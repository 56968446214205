import React from 'react';
import Header from 'components/Headers/Header';
import TextToSpeech from 'components/TextToSpeech/TextToSpeech';
import credentials from 'models/Credentials';
import getIndex from 'requests/TTSIndex';
import { observer } from 'mobx-react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

class TextToSpeechView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelData: {},
    };
  }

  componentDidMount() {
    const getTTSIndex = async () => {
      let result = await getIndex();
      if (result.status === 'success') {
        this.setState({
          modelData: result.data,
        });
      } else {
        let { status, error } = result;
        if (status === 'error_response') {
          if (('' + error.status)[0] === '4') {
            credentials.setInvalid('Invalid credential');
          } else {
            credentials.setInvalid('Credential validation failed');
          }
        } else if (status === 'error_request') {
          credentials.setInvalid('Credential validation failed');
        } else {
          credentials.setInvalid('Credential validation failed');
        }
      }
    };
    getTTSIndex();
  }

  render() {
    let { modelData } = this.state;
    return (
      <>
        <Header />
        {credentials.isValid ? (
          <Container className='mt--9 pt-7' fluid style={{ minHeight: '89vh' }}>
            <Row>
              <Col xl='12'>
                <Card className='shadow'>
                  <CardBody>
                    <Row>
                      <div className='body-wrapper' style={{ width: '100%' }}>
                        <div className='content-wrapper'>
                          {Object.keys(modelData).length > 0 && (
                            <TextToSpeech modelData={modelData} />
                          )}
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className='w-100' style={{ height: '72vh' }}></div>
        )}
      </>
    );
  }
}
export default observer(TextToSpeechView);
