import React from 'react';
import ReactDropzone from 'react-dropzone';
import credentials from 'models/Credentials';
import RecordingAudioOld from 'components/RecordingAudioOld';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import { ffmpegConvertToWav } from 'utils/Converter';
import {
  Button,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Alert,
  Table,
} from 'reactstrap';

class Enrolment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isRecording: false,
      show: props.modal,
      isFileRejected: false,
      inputName: '',
      inputFileName: '',
      fileName: '',
      files: '',
      fileAudio: '',
      items_record: [],
      gender: 'null',
      messageEnroll: '',
      result_is_verified: '',
      showRes: false,
      selectedOption: 'upload',
      speakers_list: [],
      speakers_list_temp: [],
      maxAudioDuration: 60,
      errorGender: false,
      errorGenderMsg: '',
      userAudio: null,
      isEncoding: false,
    };

    this.getRecordingStatus = this.getRecordingStatus.bind(this);
    this.getTranscriptionStatus = this.getTranscriptionStatus.bind(this);
    this.getAudioPlayStatus = this.getAudioPlayStatus.bind(this);
    this.getAudioStatus = this.getAudioStatus.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.getResultBlob = this.getResultBlob.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.mountUserList();
  }

  mountUserList() {
    fetch('/v1/sid/speakers/get', {
      method: 'GET',
      headers: {
        'access-control-allow-origin': '*',
        'Content-type': 'application/json; charset=UTF-8',
        'X-Api-Key': credentials.APIKey,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('get speakers', result, result['speakers']);
        this.setState({
          speakers_list: result['speakers'],
          speakers_list_temp: result['speakers'],
        });
      });
  }

  getRecordingStatus(value) {
    console.log('s2t -> recording', value);
    if (this.state.isRecording !== value) {
      this.setState({ isRecording: value }, (value) => {
        if (value) {
          this.setState({
            transcription: {
              status: false,
            },
          });
        }
      });
    }
  }

  getTranscriptionStatus(value) {
    console.log('s2t -> Transcription', value);
    var currentTime = Date.now() / 1000;
    if (
      this.state.text !== '' &&
      (currentTime - this.state.lastTextReceivedTime > 4 ||
        this.state.isUploadFile)
    ) {
      this.setState({
        isLoading: false,
        text: this.state.text,
        lastTextReceivedTime: currentTime,
        isUploadFile: false,
      });
    } else {
      this.setState({
        isLoading: false,
        text: this.state.text,
        lastTextReceivedTime: currentTime,
      });
    }
  }

  getAudioPlayStatus(value) {
    console.log('audio play status', value);
  }

  getAudioStatus(value) {
    console.log('audio status', value);
    this.setState({
      audioSrc: value,
    });
  }

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      inputName: e.target.value,
      inputFileName: this.state.inputFileName,
    });
  };

  onPreviewDrop = (accepted, rejected) => {
    if (accepted.length < 1) {
      this.setState({
        isFileRejected: true,
        fileAudio: '',
        files: '',
        inputFileName: '',
        fileName: '',
      });
      let timeout = setTimeout(() => {
        this.setState({
          isFileRejected: false,
        });
        clearTimeout(timeout);
      }, 3000);
    } else {
      this.setState({
        userAudio: URL.createObjectURL(accepted[0]),
        fileAudio: accepted[0],
        files: accepted,
        inputFileName: accepted[0].preview,
        fileName: accepted[0].name,
        isFileRejected: false,
      });
    }
  };

  validateGender = () => {
    if (this.state.gender === 'null') {
      this.setState({
        errorGender: true,
        errorGenderMsg: 'Jenis kelamin harus diisi',
      });

      setTimeout(() => {
        this.setState({
          errorGender: false,
          errorGenderMsg: '',
        });
      }, 4000);
    } else {
      this.submitModal();
    }
  };

  submitModal = () => {
    this.setState({
      isLoading: true,
    });
    console.log(
      'submit modal',
      this.state.gender,
      this.state.inputName,
      this.state.inputFileName,
      this.state.fileName
    );

    const fdEnroll = new FormData();
    fdEnroll.append('voice', this.state.fileAudio);
    fdEnroll.append('speaker_name', this.state.inputName);
    fdEnroll.append('gender', this.state.gender);

    fetch('/v1/sid/set_a/enroll', {
      method: 'POST',
      body: fdEnroll,
      headers: {
        'X-Api-Key': credentials.APIKey,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('enroll result', result, result['status']);
        var message = result['message'];
        if (result['status'] === 200) {
          if (message[0] === 'A')
            message = `Pengguna "${result['speaker_name']}" berhasil ditambahkan`;
          else if (message[0] === 'U')
            message = `Pengguna "${result['speaker_name']}" berhasil perbarui`;
          this.setState({
            messageEnroll: message,
            inputFileName: '',
            fileName: '',
            files: '',
            fileAudio: '',
          });
        } else {
          if (message[0] === 'V') message = 'Durasi suara tidak cukup';
          this.setState({
            messageEnroll: message,
          });
        }
        this.setState({
          items_record: [],
          selectedOption: 'upload',
          isLoading: false,
          show: true,
        });
        this.mountUserList();
      });
  };

  deleteSpeaker(name) {
    const strDeleteApi = '/v1/sid/speakers/delete';
    const raw = JSON.stringify({ speaker_name: name });
    fetch(strDeleteApi, {
      method: 'POST',
      headers: {
        'X-Api-Key': credentials.APIKey,
      },
      body: raw,
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          show: true,
          messageEnroll: result.message,
        });
        this.mountUserList();
      })
      .catch((error) => {
        this.setState({
          show: true,
          messageEnroll: error.message,
        });
      });
  }

  updateTable(e) {
    var keyword = e.target.value;
    var result = [];

    if (keyword.length > 0) {
      Object.keys(this.state.speakers_list).map((idx) => {
        let { name, gender } = this.state.speakers_list[idx];
        console.log('update table', name);
        if (name.indexOf(keyword) !== -1) result.push({ name, gender });
        return true;
      });
    } else {
      result = this.state.speakers_list;
    }

    this.setState({
      speakers_list_temp: result,
    });
  }

  getNumberSpeaker(item) {
    return parseInt(item) + 1;
  }

  handleChangeGender(val) {
    console.log('gender: ', val.target.value);
    this.setState({ gender: val.target.value });
  }

  handleOptionChange(e) {
    this.setState({
      selectedOption: e.target.value,
    });
  }

  getResultBlob = async (blob) => {
    this.setState({
      isEncoding: true,
    });
    const data = await ffmpegConvertToWav(blob);
    console.log('verify blob ', blob);
    let url = URL.createObjectURL(blob);
    console.log('url', url);
    this.setState({
      fileAudio: blob,
      files: blob,
      inputFileName: url,
      fileName: 'audio.webm',
      isRecording: false,
      userAudio: URL.createObjectURL(
        new Blob([data.buffer], { type: 'audio/wav' })
      ),
      isEncoding: false,
    });
  };

  recordingStatus() {
    this.setState({
      isRecording: true,
    });
  }

  render() {
    const speakers_list = this.state.speakers_list_temp;
    return (
      <Row>
        {this.state.show ? (
          <CustomDialog
            open={this.state.show}
            setOpen={(status) => {
              this.setState({
                show: status,
                items_record: [],
                messageEnroll: '',
                selectedOption: 'upload',
              });
            }}
            title={''}
            description={<>{this.state.messageEnroll}</>}
          />
        ) : (
          ''
        )}
        <Col xs='12' md='5' lg='5'>
          <label style={{ marginBottom: '15px', fontWeight: '600' }}>
            Tambahkan Sidik Suara Baru
          </label>
          <Row>
            <Col xl='9'>
              <form
                className='form-group'
                style={{ display: 'flex', width: '100%' }}
              >
                <div style={{ width: '30%' }}> ID: </div>
                <div style={{ width: '70%' }}>
                  <input
                    id='inputName'
                    type='text'
                    className='form-control'
                    name='inputName'
                    onChange={this.handleInputChange}
                  />
                </div>
              </form>
            </Col>
            <Col xl='9'>
              <form
                className='form-group'
                style={{ display: 'flex', width: '100%' }}
              >
                <div style={{ width: '30%' }}> Kelamin: </div>
                <Input
                  type='select'
                  name='domain'
                  id='domain-input'
                  onChange={this.handleChangeGender}
                  style={{ width: '70%' }}
                >
                  <option value='null' key='null'>
                    null
                  </option>
                  <option value='male' key='male'>
                    laki-laki
                  </option>
                  <option value='female' key='female'>
                    perempuan
                  </option>
                </Input>
              </form>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <div style={{ marginBottom: '15px' }}>Audio</div>
            </Col>
            <Col sm='8' xl='6'>
              <div
                onClick={this.recordingStatus.bind(this)}
                className='form-group'
              >
                <RecordingAudioOld
                  duration={this.state.maxAudioDuration}
                  category='enroll_verif'
                  resultBlob={this.getResultBlob}
                />
              </div>
            </Col>
            <Col sm='4' xl='3'>
              <ReactDropzone
                multiple={false}
                accept='audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg, .m4a, .webm, .weba, .opus, .ogg'
                onDrop={this.onPreviewDrop}
                style={{ borderStyle: 'none', width: '100%' }}
                className='form-group'
              >
                <Button
                  size='md'
                  type='button'
                  color='default'
                  id='upload-btn'
                  style={{ fontSize: '1rem' }}
                  disabled={this.props.disabled}
                  className='btn-icon btn-2 mr-2 btn-block'
                >
                  <i className='fas fa-upload'></i>
                </Button>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target='upload-btn'
                >
                  Unggah
                </UncontrolledTooltip>
              </ReactDropzone>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              {this.state.isEncoding ? (
                <div className='d-flex justify-content-center mb-2'>
                  <ScaleLoader height={35} width={4} radius={2} margin={2} />{' '}
                </div>
              ) : this.state.inputFileName ? (
                <audio
                  id='edit-preview-audio'
                  src={this.state.userAudio}
                  controls
                  className='form-group preview-audio w-100'
                  style={{ height: '40px' }}
                ></audio>
              ) : (
                ''
              )}
              <Alert
                color='danger'
                isOpen={this.state.isFileRejected}
                style={{ paddingTop: '11.5px', paddingBottom: '11.5px' }}
              >
                File Tidak Valid
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md='8'>
              {this.state.inputName &&
              this.state.inputFileName &&
              !this.state.isLoading ? (
                <div className='form-group'>
                  <Button
                    size='md'
                    className='btn-icon btn-2 mr-2 btn-block'
                    type='button'
                    color='default'
                    style={{ fontSize: '1rem' }}
                    disabled={this.props.disabled}
                    onClick={() => {
                      this.validateGender();
                    }}
                  >
                    Tambahkan
                  </Button>
                </div>
              ) : (
                <div className='form-group'>
                  <Button
                    size='md'
                    className='btn-icon btn-2 mr-2 btn-block'
                    type='button'
                    color='default'
                    style={{ fontSize: '1rem' }}
                    disabled={true}
                  >
                    Tambahkan
                    {this.state.isLoading && (
                      <span className='ml-2'>
                        <i
                          className='fa fa-circle-notch fa-spin text-white'
                          style={{ fontSize: '16px' }}
                        ></i>
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Alert color='danger' isOpen={this.state.errorGender}>
                {this.state.errorGenderMsg}
              </Alert>
            </Col>
          </Row>
        </Col>
        <Col xs='12' md='6' lg='6'>
          <Row>
            <Col lg='8' style={{ marginBottom: '15px', fontWeight: '600' }}>
              Daftar ID Pengguna
            </Col>
            <Col lg='4' style={{ marginBottom: '15px' }}>
              <input
                id='searchName'
                type='text'
                className='form-control'
                name='searchName'
                placeholder='Pencarian'
                onChange={this.updateTable}
              />
            </Col>
          </Row>
          <Table size='sm' responsive>
            <thead>
              <tr>
                <th className='small-column' style={{ width: '15%' }}>
                  Nomor
                </th>
                <th>ID Pengguna</th>
                <th style={{ width: '15%' }}>Hapus</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(speakers_list).map((item) => (
                <tr key={speakers_list[item].name}>
                  <td className='small-column'>
                    {this.getNumberSpeaker(item)}
                  </td>
                  <td>{speakers_list[item].name}</td>
                  <td>
                    <i
                      title='Hapus'
                      onClick={this.deleteSpeaker.bind(
                        this,
                        speakers_list[item].name
                      )}
                      className='fa fa-trash icon-configuration-verify configuration-button delete-button'
                      style={{ margin: 'auto 5px' }}
                      aria-hidden='true'
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

export default Enrolment;
