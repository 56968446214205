import CustomDataGrid from '../components/CustomDataGrid/CustomDataGrid';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useStoreToken } from 'components/Credentials/APIKeyModal';
import { useHistory } from 'react-router-dom';
import { fetchLog } from 'models/Admin';
import { CSVLink } from 'react-csv';

const AdminPage = () => {
  const [data, setData] = useState([]);
  const token = useStoreToken((state) => state.token);
  const history = useHistory();

  const columns = [
    {
      field: 'No',
      headerName: 'No',
      flex: 1,
      renderCell: (params) => {
        return <>{params.rowIndex !== undefined ? params.rowIndex + 1 : ''}</>;
      },
      sortable: false,
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'organization', headerName: 'Organization', flex: 1 },
    { field: 'phone_number', headerName: 'Phone Number', flex: 1 },
    { field: 'quota', headerName: 'Quota', flex: 1 },
    { field: 'usage', headerName: 'Usage', flex: 1 },
    {
      field: 'first_access',
      headerName: 'First Access',
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) return '';
        return dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      field: 'latest_access',
      headerName: 'Latest Access',
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) return '';
        return dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
      },
    },
  ];

  useEffect(() => {
    const fetchLogData = async () => {
      const result = await fetchLog(token);
      if (result !== false) {
        setData(result);
      } else {
        history.goBack();
      }
    };
    fetchLogData();
  }, [token, history]);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className='container'>
      <div className='d-flex mt-2 justify-content-between align-items-center'>
        <button onClick={handleBack} className='btn btn-info'>
          <i className='fa fa-arrow-alt-circle-left'></i> Go Back
        </button>
        <div className='d-flex align-items-center mb-2'>
          <CSVLink data={data}>
            <button className='btn btn-success'>Download CSV</button>
          </CSVLink>
        </div>
      </div>
      <hr className='my-2' />
      <CustomDataGrid columns={columns} rows={data} />
    </div>
  );
};

export default AdminPage;
