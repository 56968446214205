import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const Register = ({ registerData, isSubmitRegister }) => {
  const textInput = useRef(null);
  const [data, setData] = useState({
    phone: '',
    organization: '',
  });

  useEffect(() => {
    setTimeout(function () {
      if (textInput.current) {
        textInput.current.focus();
      }
    }, 100);
  }, []);

  const handleSubmit = () => {
    registerData(data.organization, data.phone);
  };

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <>
      <Form role='form'>
        <FormGroup>
          <InputGroup className='input-group-alternative'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i id='lock-icon' className='ni ni-building' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder='Organization'
              type='text'
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                const { value } = e.target;
                setData((c) => ({ ...c, organization: value }));
              }}
              innerRef={textInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className='input-group-alternative'>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <i id='lock-icon' className='ni ni-badge' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder='Phone Number'
              type='number'
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                const { value } = e.target;
                setData((c) => ({ ...c, phone: value }));
              }}
            />
          </InputGroup>
        </FormGroup>
        <div className='text-center'>
          <Button
            className='my-2 btn-icon btn-2'
            color='default'
            type='button'
            onClick={handleSubmit}
            disabled={isSubmitRegister}
          >
            Register
            {isSubmitRegister && (
              <span className='ml-2'>
                <i
                  className='fa fa-circle-notch fa-spin text-white'
                  style={{ fontSize: '16px' }}
                ></i>
              </span>
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Register;
