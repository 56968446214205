import React, { useState } from 'react';
import RecordRTC, { MediaStreamRecorder } from 'recordrtc';
import { Button, Row, Col } from 'reactstrap';
import urlConfig from 'config';
import { downloadObjectUrl } from 'utils/Converter';

function RecordingAudioRealTime({
  model,
  setResultTranscription,
  setAudioFile,
  setError,
  setStatus,
  single,
  interim,
  setSingle,
  setInterim,
  status,
}) {
  const [recorderEngine, setRecorderEngine] = useState(null);
  const [varInterval, setVarInterval] = useState(null);
  const [webSocketInstance, setWebSocketInstance] = useState(null);
  const [singleUtterance, setSingleUtterance] = useState(
    single === undefined ? false : single === null ? false : single
  );
  const [interimResults, setInterimResults] = useState(
    interim === undefined ? true : interim === null ? true : interim
  );
  const [streamInstance, setStreamInstance] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [stringDuration, setStringDuration] = useState('00:00');
  const [counterInterval, setCounterInterval] = useState(null);
  const [buttonStatus, setButtonStatus] = useState('btn-default');
  const [audioBlob, setAudioBlob] = useState([]);
  const [canDownload, setCanDownload] = useState(0);
  // API KEY for ASR Online decoder
  const API_KEY = 'eBKT66roIFqyS87pe4cqsZZ6wTMCdKDpWaOKOeot';
  let tempTranscriptionFinal = '';
  let tempInterval;
  let tempRecorder;
  let tempStream;
  let firstError = false;
  // Config of decoder
  const config = {
    audio_content: '',
    streaming_config: {
      config: {
        encoding: 'WAV',
        sample_rate_hertz: 16000,
        language_code: 'id-ID',
      },
      api_key: API_KEY,
      single_utterance: singleUtterance,
      interim_results: interimResults,
    },
  };

  // Message to send on first time to controller
  const firstMessage = (ws) => {
    let data = {
      streaming_config: config.streaming_config,
    };
    data.streaming_config.single_utterance = singleUtterance;
    data.streaming_config.interim_results = interimResults;
    if (ws.readyState === ws.OPEN) {
      ws.send(JSON.stringify(data));
    } else {
      firstError = true;
      setAudioBlob([]);
      setStatus('danger');
      setError('Connection Lost');
      setIsRecording(false);
      setButtonStatus('btn-default');
      clearInterval(tempInterval);
      tempStream.stop();
      tempRecorder.stopRecording();
      setStatus('Error');
    }
  };

  // Message to send on the last time to controller
  // The controller will automatically close the connection
  const lastMessage = (ws) => {
    let message = config;
    message.audio_content = '';
    message.streaming_config.single_utterance = singleUtterance;
    message.streaming_config.interim_results = interimResults;
    if (ws.readyState === ws.OPEN) {
      ws.send(JSON.stringify(message));
    } else {
      setAudioBlob([]);
      setStatus('danger');
      setError('Connection Lost');
      setIsRecording(false);
      setButtonStatus('btn-default');
      clearInterval(tempInterval);
      tempStream.stop();
      tempRecorder.stopRecording();
      setStatus('Error');
    }
  };

  // Message to send with the audio
  const sendMessageWithAudio = (ws, blob) => {
    let message = config;
    message.streaming_config.single_utterance = singleUtterance;
    message.streaming_config.interim_results = interimResults;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = decodeURIComponent(reader.result);
      message.audio_content = base64data.split(',')[1];
      if (ws.readyState === ws.OPEN) {
        ws.send(JSON.stringify(message));
      } else {
        if (singleUtterance === true && firstError === false) {
          setUpPlayer(null);
          setStringDuration(`00:00`);
        } else if (firstError === false) {
          setError('Connection Lost');
          setStringDuration(`00:00`);
        } else {
          setStringDuration(`00:00`);
        }
        setAudioBlob([]);
        setIsRecording(false);
        setButtonStatus('btn-default');
        clearInterval(tempInterval);
        tempStream.stop();
        tempRecorder.stopRecording();
      }
    };
  };

  // Function to handle click the record button
  const onClickRecord = () => {
    if (isRecording === false) {
      setStatus('Processing');
      setIsRecording(true);
      setButtonStatus('btn-danger');
      startRecord();
    } else {
      setIsRecording(false);
      setButtonStatus('btn-default');
      onClickStop();
    }
  };

  // Handle start recording
  const startRecord = () => {
    const { version, basename, origin } = urlConfig.api.ws;
    setAudioFile(null);
    setResultTranscription('');

    setIsRecording(true);
    counterDuration();
    // Connection to websocket
    const url = `wss://${origin}/${version}/${basename}/${model}/realtime`;
    const ws = new WebSocket(url);
    ws.onerror = (evt) => {
      setError(`WebSocket error observed: ${evt}`);
      firstError = true;
    };
    ws.onopen = () => {
      console.log('websocket connected');
      firstMessage(ws);
    };
    ws.onmessage = (evt) => {
      // Handle data from the Controller
      let data = JSON.parse(evt.data);
      console.log(evt);
      let output;
      let tempTranscription = '';
      try {
        tempTranscription = data.result.transcript;
        if (data.result.is_final === true) {
          tempTranscriptionFinal =
            tempTranscriptionFinal + tempTranscription + '.\n';
          tempTranscription = '';
        }
        output = tempTranscriptionFinal + tempTranscription;
        let tempOutput = output.split(' ');
        tempOutput = tempOutput.filter((data) => {
          return data.includes('<') === false;
        });
        output = tempOutput.join(' ');
        setResultTranscription(output);
      } catch (error) {
        data = JSON.parse(evt.data);
        setError(data.error.message);
        firstError = true;
      }
    };
    ws.onclose = () => {
      console.log('websocket disconnected');
      setTimeout(() => {
        setAudioBlob([]);
        if (singleUtterance === true && firstError === false) {
          setStatus('Success');
        } else if (firstError === true) {
          setStatus('Error');
        } else {
          setStatus('Success');
        }
      }, 2000);
    };
    setWebSocketInstance(ws);
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        recorderType: MediaStreamRecorder,
      })
      .then(async function (stream) {
        let recorder = RecordRTC(stream, {
          type: 'audio',
          disableLogs: true,
        });
        tempRecorder = recorder;
        tempStream = stream;
        setStreamInstance(stream);
        setRecorderEngine(recorder);
        recorder.startRecording();
        const intervalSlice = setInterval(() => {
          recorder.stopRecording(() => {
            const blob = recorder.getBlob();
            if (canDownload >= 10) {
              downloadObjectUrl(blob, 'audio.webm');
            }
            createArrayBuffer(blob);
            sendMessageWithAudio(ws, blob);
            recorder.startRecording();
          });
        }, 1000);
        setVarInterval(intervalSlice);
      });
  };

  // Handle when recording stopped
  const onClickStop = () => {
    clearInterval(counterInterval);
    setStringDuration(`00:00`);
    recorderEngine.stopRecording(() => {
      const blob = recorderEngine.getBlob();
      setUpPlayer(blob);
      if (canDownload >= 10) {
        downloadObjectUrl(blob, 'audio.webm');
      }
      setAudioBlob([]);
      sendMessageWithAudio(webSocketInstance, blob);
      clearInterval(varInterval);
      setTimeout(() => {
        streamInstance.stop();
        lastMessage(webSocketInstance);
      }, 1000);
    });
  };

  // Count recording duration
  const counterDuration = () => {
    let counter = 0;
    tempInterval = setInterval(() => {
      counter++;
      let min = duration,
        sec;
      sec = counter % 60;
      min = Math.floor(counter / 60);
      let secStr = sec.toString();
      let minStr = min.toString();
      if (sec < 10) {
        secStr = '0' + secStr;
      }
      if (min < 10) {
        minStr = '0' + minStr;
      }
      setStringDuration(`${minStr}:${secStr}`);
      setDuration(counter);
    }, 1000);
    setCounterInterval(tempInterval);
  };

  // Create Audio Buffer for Playback
  const createArrayBuffer = (superBlob) => {
    let x = audioBlob;
    x.push(superBlob);
  };

  // Create Audio File for Playback
  const setUpPlayer = (blob) => {
    if (blob === null) {
      setAudioFile(audioBlob);
    } else {
      let x = audioBlob;
      x.push(blob);
      setAudioFile(x);
    }
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <Button
            className={`btn-icon btn-2 mr-2 btn-block ${buttonStatus}`}
            style={{ fontSize: '1rem' }}
            onClick={onClickRecord}
            disabled={
              (status === 'Processing' && isRecording === false) === true
                ? true
                : false
            }
          >
            {isRecording ? (
              <i className='fas fa-microphone-slash'></i>
            ) : (
              <i className='fas fa-microphone'></i>
            )}
            {<span className='ml-1'>{stringDuration}</span>}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mt-2'>
          <div className='d-flex align-items-center'>
            <input
              type='checkbox'
              className='mr-3'
              checked={singleUtterance}
              disabled={status === 'Processing' ? true : false}
              onChange={() => {
                setSingleUtterance(!singleUtterance);
                setSingle(!singleUtterance);
              }}
            />
            <div>Kalimat pertama saja</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mt-2'>
          <div className='d-flex align-items-center'>
            <input
              type='checkbox'
              className='mr-3'
              checked={interimResults}
              disabled={status === 'Processing' ? true : false}
              onChange={() => {
                setInterimResults(!interimResults);
                setInterim(!interimResults);
                let x = canDownload;
                x = x + 1;
                setCanDownload(x);
              }}
            />
            <div>Tampilkan hasil sementara</div>
          </div>
        </Col>
      </Row>
      {canDownload >= 10000 ? (
        <Row>
          <Col sm={12} className='mt-2'>
            <div className='d-flex align-items-center text-danger'>
              Download Chucks Enabled
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </div>
  );
}

export default RecordingAudioRealTime;
