import React from 'react';

function Sentence({ text, highlighted, error, errMsg }) {
  return (
    <span
      className={
        highlighted ? 'bg-info text-white' : ' '
        // + (error ? 'bg-danger text-white' : '') // Menambahkan logic untuk styling
      }
      //   data-toggle='tooltip'
      //   title={error ? `Gateway Timeout Code : ${errMsg}` : 'Success'}
    >
      {text}.
    </span>
  );
}

export default Sentence;
