import React from 'react';
import RecordRTC from 'recordrtc';
import { MediaStreamRecorder } from 'recordrtc';
import { Button, UncontrolledTooltip } from 'reactstrap';

class RecordingAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordAudio: null,
      isRecording: false,
      blob: '',
      timeoutHandle: '',
      timeCounter: 0,
      intervalHandle: '',
    };

    this.requestUserMedia = this.requestUserMedia.bind(this);
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
    this.getUserMedia = this.getUserMedia.bind(this);
  }

  componentDidMount() {
    let { duration } = this.props;
    if (duration) {
      this.setState({ timeCounter: duration });
    }
  }

  requestUserMedia() {
    this.getUserMedia((stream) => {
      this.setState({ src: window.URL.createObjectURL(stream) });
    });
  }

  startRecord() {
    console.log('start recording');
    this.setState({
      isRecording: true,
    });
    let { onStartRecording } = this.props;
    if (onStartRecording) {
      onStartRecording();
    }
    this.getUserMedia((stream) => {
      this.setState({
        recordAudio: RecordRTC(stream, {
          type: 'audio',
          recorderType: MediaStreamRecorder,
        }),
      });
      this.state.recordAudio.startRecording();
      let recordAudio = this.state.recordAudio;
      recordAudio.microphone = stream;
      this.setState({
        recordAudio: recordAudio,
      });
    });
    let { duration } = this.props;
    if (duration) {
      let timeoutHandle = setTimeout(() => {
        this.stopRecord();
      }, duration * 1000);
      this.setState({ timeoutHandle: timeoutHandle });

      let intervalHandle = setInterval(() => {
        let timeCounter = this.state.timeCounter - 1;
        this.setState({ timeCounter: timeCounter });
      }, 1000);
      this.setState({ intervalHandle: intervalHandle });
    }
  }

  handleRecordClick = (e) => {
    this.state.isRecording ? this.stopRecord() : this.startRecord();
  };

  getPrintedCounter = () => {
    let { timeCounter } = this.state;
    let minutes = Math.floor(timeCounter / 60);
    let seconds = timeCounter % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  };

  stopRecord() {
    let { duration } = this.props;
    if (duration) {
      clearTimeout(this.state.timeoutHandle);
      clearInterval(this.state.intervalHandle);
      this.setState({ timeCounter: duration });
    }
    this.setState({
      isRecording: false,
    });
    this.state.recordAudio.microphone.stop();
    this.state.recordAudio.stopRecording(() => {
      let blob = this.state.recordAudio.getBlob();
      let { onStopRecording } = this.props;
      if (onStopRecording) {
        onStopRecording(blob);
      }
    });
  }

  getUserMedia(callback) {
    navigator.getUserMedia({ audio: true }, callback, (error) =>
      alert(JSON.stringify(error))
    );
  }

  render() {
    return (
      <>
        <Button
          type='button'
          onClick={this.handleRecordClick}
          className='btn-icon btn-2 mr-2 btn-block'
          color={this.state.isRecording ? 'danger' : 'default'}
          id='record-btn'
          style={{ fontSize: '1rem' }}
          disabled={this.props.disabled}
        >
          {this.state.isRecording ? (
            <i className='fas fa-microphone-slash'></i>
          ) : (
            <i className='fas fa-microphone'></i>
          )}
          {this.props.duration && (
            <span className='ml-1'>{this.getPrintedCounter()}</span>
          )}
        </Button>
        <UncontrolledTooltip placement='top' target='record-btn'>
          {this.state.isRecording ? 'Berhenti' : 'Rekam'}
        </UncontrolledTooltip>
      </>
    );
  }
}

export default RecordingAudio;
