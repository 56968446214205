import React from 'react';
import { Grid, Box, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function CustomSlider({ value, setValue, isEnable }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display='flex' alignItems='center' width='100%' mt={1.8}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <PrettoSlider
            disabled={isEnable}
            value={value}
            aria-labelledby='discrete-slider-small-steps'
            min={-100}
            max={100}
            valueLabelDisplay='auto'
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
