import { observable, computed, action, autorun } from 'mobx';

class Transcription {
  @observable model: string;
  @observable realtimeModel: string;
  @observable audio: Object = '';
  @observable source: string = '';
  @observable transcript: Object = '';
  @observable key: number;

  constructor(model: string, realtimeModel: string) {
    this.model = model;
    this.realtimeModel = realtimeModel;
    this.key = Math.random();
    autorun(() => console.log(this.log));
  }

  @computed
  get log() {
    return {
      model: this.model,
      realtimeModel: this.realtimeModel,
      audio: this.audio,
      transcript: this.transcript,
    };
  }

  @action
  setModel(model: string) {
    this.model = model;
  }

  @action
  setRealtimeModel(model: string) {
    this.realtimeModel = model;
  }

  @action
  setBlob(blob: Blob) {
    this.source = URL.createObjectURL(blob);
    this.audio = new File([blob], 'audio.webm');
    this.setTranscript('');
  }

  @action
  setAudio(audio: any) {
    this.audio = audio;
  }

  @action
  setSource(source: string) {
    this.source = source;
  }

  _replaceUnknown(text: string) {
    return text.replace(/(<+\w+>)/g, '').trim();
  }

  @action
  setTranscript(transcript: any) {
    let newTranscript = transcript;
    if (transcript) {
      newTranscript.text = transcript.text
        ? this._replaceUnknown(newTranscript.text)
        : '';
    }
    this.transcript = newTranscript;
  }

  @action
  resetAudio() {
    this.audio = '';
    this.source = this.transcript = '';
  }

  @action
  duplicate() {
    let duplication = new Transcription(this.model, this.realtimeModel);
    duplication.setAudio(this.audio);
    duplication.setSource(this.source);
    duplication.setTranscript(this.transcript);
    return duplication;
  }
}

export default Transcription;
