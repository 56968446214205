import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'App.css';

import DashboardLayout from 'layouts/Dashboard';
import AdminPage from 'pages/AdminPage';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/admin'
            render={(props) => <AdminPage {...props} />}
          />
          <Route path='/' render={(props) => <DashboardLayout {...props} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
