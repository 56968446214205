import { observable, action, autorun } from 'mobx';

let credentials = observable(
  {
    APIKey : process.env.REACT_APP_API_KEY || '',
    validationStatus : process.env.REACT_APP_API_KEY ? 'valid': 'unknown', // unknown, valid, validating, invalid
    message: '',

    // computed property
    get log() {
      return {
        APIKey: this.APIKey, validationStatus: this.validationStatus
      }
    },
    get isInvalid() {
      return this.validationStatus === 'invalid'
    },
    get isValid() {
      return this.validationStatus === 'valid'
    },
    get isUnknown() {
      return this.validationStatus === 'unknown'
    },
    get isValidating() {
      return this.validationStatus === 'validating'
    },

    // action method
    setAPIKey(APIKey) {
      this.APIKey = APIKey;
      this.setUnknown();
    },
    setInvalid(message) {
      this.validationStatus = 'invalid'
      this.message = message
    },
    setValid() {
      this.validationStatus = 'valid'
    },
    setUnknown() {
      this.validationStatus = 'unknown'
    },
    setValidating() {
      this.validationStatus = 'validating'
    }
  },
  {
    setAPIKey: action,
    setValid: action,
    setInvalid: action,
    setUnknown: action,
    setValidating: action,
  }
)

autorun(() => console.log(credentials.log))

export default credentials;
