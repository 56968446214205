const LOCAL_AUTH_KEY = 'AUTHENTICATION';

type UserData = {
  email: string;
  googleToken: string;
  apiKey: string;
};

export function saveCredential(authentication: UserData): void {
  localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(authentication));
}

export function getCredential(): UserData {
  const authJSON = localStorage.getItem(LOCAL_AUTH_KEY);
  if (!authJSON)
    return {
      email: '',
      apiKey: '',
      googleToken: '',
    };
  let auth = JSON.parse(authJSON);
  return auth;
}

export function clearCredential(): void {
  localStorage.removeItem(LOCAL_AUTH_KEY);
}
