const { createWorker } = require('@ffmpeg/ffmpeg');

// Use FFmpeg to convert to wav
export const ffmpegConvertToWav = async (blob) => {
  const worker = createWorker({
    logger: ({ message }) => console.log(message),
  });
  await worker.load();
  await worker.write('audio.webm', blob);
  await worker.transcode('audio.webm', 'audio.wav');
  const { data } = await worker.read('audio.wav');
  await worker.terminate();
  return data;
};

// Function to download (input is a objectURL)
export function downloadObjectUrl(objectURL) {
  const link = document.createElement('a');
  link.href = objectURL;
  link.download = 'audio.wav';
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  document.body.removeChild(link);
}
