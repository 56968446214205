import ReactDropzone from 'react-dropzone';
import credentials from 'models/Credentials';
import RecordingAudioOld from 'components/RecordingAudioOld';
import ScaleLoader from 'react-spinners/ScaleLoader';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import React, { Component } from 'react';
import { ffmpegConvertToWav } from 'utils/Converter';
import {
  Button,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';

class Identification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isRecording: false,
      show: props.modal,
      isFileRejected: false,
      inputName: '',
      inputFileName: '',
      fileName: '',
      files: '',
      fileAudio: '',
      items_record: [],
      gender: 'null',
      modalType: '',
      messageModal: '',
      result_is_verified: '',
      identifyName: '',
      identifyGender: '',
      showRes: false,
      selectedOption: 'upload',
      speakers_list: [],
      maxAudioDuration: 60,
      errorGender: false,
      errorGenderMsg: '',
      userAudio: null,
      isEncoding: false,
    };

    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.getResultBlob = this.getResultBlob.bind(this);
  }

  validateGender = () => {
    if (this.state.gender === 'null') {
      this.setState({
        errorGender: true,
        errorGenderMsg: 'Jenis kelamin harus diisi',
      });

      setTimeout(() => {
        this.setState({
          errorGender: false,
          errorGenderMsg: '',
        });
      }, 4000);
    } else {
      this.identifyAudio();
    }
  };

  identifyAudio = () => {
    this.setState({
      isLoading: true,
    });

    console.log(
      'submit button identify',
      this.state.files,
      this.state.fileAudio,
      this.state.gender,
      this.state.inputName,
      this.state.inputFileName,
      this.state.fileName
    );

    const fdIdentify = new FormData();
    fdIdentify.append('voice', this.state.fileAudio);
    if (this.state.gender !== 'no_gender')
      fdIdentify.append('gender', this.state.gender);

    fetch('/v1/sid/set_a/identify', {
      method: 'POST',
      body: fdIdentify,
      headers: {
        'X-Api-Key': credentials.APIKey,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const { message: messageResult, result: resultArray } = result;

        if (messageResult !== undefined) {
          this.setState({
            messageModal: messageResult,
          });
        } else if (resultArray === undefined) {
          this.setState({
            messageModal: 'Permintaan Gagal',
          });
        } else {
          if (Object.keys(result).length === 1) {
            if (result['result'].length === 0) {
              this.setState({
                messageModal: 'Maaf suara tersebut tidak dikenali',
              });
            } else {
              let gender =
                result['result'][0].gender === 'male'
                  ? 'laki-laki'
                  : 'perempuan';

              this.setState({
                identifyName: result['result'][0].name,
                identifyGender: gender,
                modalType: 'result',
                inputFileName: '',
                fileName: '',
                files: '',
                fileAudio: '',
              });
            }
          } else {
            console.log('bad request', result['message']);
            let message = result['message']
              ? result['message']
              : 'Permintaan Gagal';
            this.setState({
              messageModal: message,
            });
          }
        }

        this.setState({
          show: true,
          isLoading: false,
        });

        console.log('gender', this.state.gender);
      });
  };

  getResultBlob = async (blob) => {
    this.setState({
      isEncoding: true,
    });
    const data = await ffmpegConvertToWav(blob);
    console.log('verify blob ', blob);
    let url = URL.createObjectURL(blob);
    console.log('url', url);
    this.setState({
      fileAudio: blob,
      files: blob,
      inputFileName: url,
      fileName: 'audio.webm',
      isRecording: false,
      userAudio: URL.createObjectURL(
        new Blob([data.buffer], { type: 'audio/wav' })
      ),
      isEncoding: false,
    });
  };

  onPreviewDrop = (accepted, rejected) => {
    if (accepted.length < 1) {
      this.setState({
        isFileRejected: true,
        fileAudio: '',
        files: '',
        inputFileName: '',
        fileName: '',
      });
      let timeout = setTimeout(() => {
        this.setState({
          isFileRejected: false,
        });
        clearTimeout(timeout);
      }, 3000);
    } else {
      this.setState({
        userAudio: URL.createObjectURL(accepted[0]),
        fileAudio: accepted[0],
        files: accepted,
        inputFileName: accepted[0].preview,
        fileName: accepted[0].name,
        isFileRejected: false,
      });
    }
  };

  handleInputChange = (e) => {
    e.preventDefault();

    this.setState({
      inputName: e.target.value,
      inputFileName: this.state.inputFileName,
    });
  };

  handleChangeGender(val) {
    console.log('gender: ', val.target.value);
    this.setState({ gender: val.target.value });
  }

  recordingStatus() {
    this.setState({
      isRecording: true,
    });
  }

  render() {
    return (
      <>
        {this.state.show && this.state.modalType === 'result' ? (
          <CustomDialog
            open={this.state.show}
            setOpen={(status) => {
              this.setState({
                show: status,
                items_record: [],
                modalType: '',
                messageModal: '',
                selectedOption: '',
              });
            }}
            title={''}
            description={
              <>
                <div>
                  <b>Hasil:</b>
                  <br></br>
                  ID Pengguna: <b>{this.state.identifyName}</b>
                  <br></br>
                  Jenis Kelamin: <b>{this.state.identifyGender}</b>{' '}
                </div>
              </>
            }
          />
        ) : this.state.show ? (
          <CustomDialog
            open={this.state.show}
            setOpen={(status) => {
              this.setState({
                show: status,
                items_record: [],
                messageEnroll: '',
                selectedOption: 'upload',
              });
            }}
            title={''}
            description={<>{this.state.messageModal}</>}
          />
        ) : (
          ''
        )}
        <Col xs='12' md='7'>
          <label style={{ fontWeight: '600', marginBottom: '20px' }}>
            Identifikasi Sidik Suara Pengguna
          </label>
          <form className='form-group' style={{ display: 'flex' }}>
            <Col md='6'>
              <Row>
                <Input
                  type='select'
                  name='domain'
                  id='domain-input'
                  onChange={this.handleChangeGender}
                  value={this.state.gender}
                >
                  <option value='null' key='null'>
                    null
                  </option>
                  <option value='male' key='male'>
                    laki-laki
                  </option>
                  <option value='female' key='female'>
                    perempuan
                  </option>
                </Input>
              </Row>
            </Col>
          </form>
        </Col>
        <Col xs='12' md={{ size: '8', offset: '4' }}>
          <div className='mb-2'>Audio</div>
          <Row>
            <Col sm='8' md='5' xl='3'>
              <div
                onClick={this.recordingStatus.bind(this)}
                className='form-group'
              >
                <RecordingAudioOld
                  duration={this.state.maxAudioDuration}
                  category='enroll_verif'
                  resultBlob={this.getResultBlob}
                />
              </div>
            </Col>
            <Col sm='4' md='3' xl='2'>
              <ReactDropzone
                multiple={false}
                accept='audio/wav, audio/x-wav, audio/wave, audio/x-pn-wav, audio/mp3, audio/mpeg, .m4a, .webm, .weba, .opus, .ogg'
                onDrop={this.onPreviewDrop}
                style={{ borderStyle: 'none' }}
                className='form-group'
              >
                <Button
                  size='md'
                  type='button'
                  className='btn-icon btn-2 mr-2 btn-block'
                  color='default'
                  id='upload-btn'
                  style={{ fontSize: '1rem' }}
                  disabled={this.props.disabled}
                >
                  <i className='fas fa-upload'></i>
                </Button>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target='upload-btn'
                >
                  Unggah
                </UncontrolledTooltip>
              </ReactDropzone>
            </Col>
            <Col md='6'>
              {this.state.isEncoding ? (
                <div className='d-flex justify-content-center mb-2'>
                  <ScaleLoader height={35} width={4} radius={2} margin={2} />{' '}
                </div>
              ) : this.state.inputFileName ? (
                <audio
                  id='edit-preview-audio'
                  src={this.state.userAudio}
                  style={{ height: '40px' }}
                  className='form-group preview-audio w-100'
                  controls
                ></audio>
              ) : (
                ''
              )}
              <Alert
                color='danger'
                isOpen={this.state.isFileRejected}
                style={{ paddingTop: '11.5px', paddingBottom: '11.5px' }}
              >
                File Tidak Valid
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col sm='4' md='5' xl='3'>
              {this.state.gender &&
              this.state.inputFileName &&
              !this.state.isLoading ? (
                <div className='form-group'>
                  <Button
                    size='md'
                    type='button'
                    className='btn-icon btn-2 mr-2 btn-block'
                    color='default'
                    style={{ fontSize: '1rem' }}
                    disabled={this.props.disabled}
                    onClick={() => {
                      this.validateGender();
                    }}
                  >
                    Proses
                  </Button>
                </div>
              ) : (
                <div className='form-group'>
                  <Button
                    size='md'
                    type='button'
                    className='btn-icon btn-2 mr-2 btn-block'
                    color='default'
                    style={{ fontSize: '1rem' }}
                    disabled={true}
                  >
                    Proses
                    {this.state.isLoading && (
                      <span className='ml-2'>
                        <i
                          className='fa fa-circle-notch fa-spin text-white'
                          style={{ fontSize: '16px' }}
                        ></i>
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Alert color='danger' isOpen={this.state.errorGender}>
                {this.state.errorGenderMsg}
              </Alert>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Identification;
