import React from 'react';
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import { Card } from 'reactstrap';
import { observer } from 'mobx-react';

// import audio from 'assets/audio/beep.wav';
// import MediaElement from 'components/MediaElement';

import NonRealTimeTranscription from './NonRealTimeTranscription';
import RealTimeTranscription from './RealTimeTranscription';
import Tabs from '../Tabs';

class Transcription extends React.Component {
  render() {
    const styleCursor = {
      cursor: 'pointer',
    };

    let {
      transcription,
      transcriptionReal,
      modelData,
      realtimeModelData,
      remove,
      duplicate,
      innerRef,
      ...props
    } = this.props;
    return (
      <Card className='shadow' {...props}>
        <Tabs>
          <div label='Non Real Time' styleBorder={styleCursor}>
            <p className='description px-4'>
              Halaman ini digunakan untuk mentranskrip suara secara tidak real
              time. Suara disimpan terlebih dahulu, lalu proses transkrip
              dimulai setelah pengguna menekan tombol transkrip.
            </p>
            <NonRealTimeTranscription {...this.props} />
          </div>
          <div label='Real Time' styleBorder={styleCursor}>
            <p className='description px-4'>
              Halaman ini digunakan untuk mentranskrip suara secara real time.
              Proses transkripsi akan berjalan seiring pengguna merekam suara.
            </p>
            <RealTimeTranscription {...this.props} />
          </div>
        </Tabs>
      </Card>
    );
  }
}

export default observer(Transcription);
