import Axios, { AxiosRequestConfig } from 'axios';
import urlConfig from 'config';

const { origin, basename } = urlConfig.api.auth;

export const fetchLog = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data: result } = await Axios.get(
      `${origin}/${basename}/list_users`,
      config
    );
    return result;
  } catch (error) {
    return false;
  }
};
