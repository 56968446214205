import React from 'react';
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Row,
  Col,
  CardBody,
  Input,
  FormGroup,
  UncontrolledTooltip,
  Alert,
  CardFooter,
} from 'reactstrap';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { observer } from 'mobx-react';

import Uploader from 'components/Uploader';
import Transcript from 'components/SpeechToText/Transcript';
import RecordingAudio from 'components/RecordingAudio';
import CustomAudioPlayer from '../CustomAudioPlayer';
import ScaleLoader from 'react-spinners/ScaleLoader';
import transcript from 'requests/ASRTranscript';
import { ffmpegConvertToWav } from 'utils/Converter';

// import audio from 'assets/audio/beep.wav';
// import MediaElement from 'components/MediaElement';

class NonRealTimeTranscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRecording: false,
      fileAlertMessage: '',
      fileAlertVisible: false,
      isTranscribing: false,
      isTranscriptionFinished: true,
      isTranscriptionError: false,
      transcriptionAlertTitle: '',
      transcriptionAlertMessage: '',
      shotWaitingTimer: false,
      initialCounter: 21,
      waitingCounter: 21,
      maxAudioDuration: 30,
      maxAudioSize: 10,
      audioFile: null,
      isEncoding: false,
    };

    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.setFile = this.setFile.bind(this);
    this.transcribeAudio = this.transcribeAudio.bind(this);
    this.saveTranscriptResult = this.saveTranscriptResult.bind(this);
    this.setAudioRecordBlob = this.setAudioRecordBlob.bind(this);
  }

  componentDidMount() {
    let { transcription } = this.props;
    let audio = transcription.audio.preview;
    if (audio === undefined) {
      audio = transcription.audio;
    }
    if (transcription.audio !== '') {
      this.setState({
        audioFile: audio,
      });
    }
  }

  async transcribeAudio() {
    this.setState({
      isTranscribing: true,
      isLoading: true,
      isTranscriptionFinished: false,
    });

    let { transcription } = this.props;
    transcription.setTranscript('');

    let result = await transcript(transcription);
    if (result.status === 'success') {
      transcription.setTranscript(result.data);
      this.setState({
        isLoading: false,
        isTranscribing: false,
        isTranscriptionFinished: true,
      });
    } else if (result.status === 'error_request') {
      this.setTranscriptionFailed(false, 'Error', 'Bad Request');
    } else if (result.status === 'error_response') {
      if (result.error.status === 403) {
        this.setTranscriptionFailed(
          true,
          'Kuota habis',
          'Mohon maaf, kuota anda telah habis. Silahkan hubungi business@prosa.ai untuk informasi lebih lanjut.'
        );
      } else {
        this.setTranscriptionFailed(
          true,
          'Server is on fully loaded',
          'Please wait a moment for the next try...'
        );
      }
    } else {
      this.setTranscriptionFailed(false, 'Error', 'Unknown Error');
    }
  }

  saveTranscriptResult = async () => {
    let { transcription } = this.props;
    let { transcript } = transcription;
    let { text } = transcript;
    let filename = 'Transcription_result.zip';
    const zip = new JSZip();
    zip.file('transcript.txt', text);
    let blob = await fetch(this.state.audioFile).then((r) => r.blob());
    zip.file('audio.wav', blob);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, filename);
    });
  };

  setFile(file) {
    let { transcription } = this.props;
    transcription.resetAudio();
    transcription.setAudio(file);
    this.setState({
      isTranscriptionFinished: false,
      isTranscriptionError: false,
      audioFile: file.preview,
    });
  }

  setAudioRecordBlob = async (blob) => {
    this.setState({
      isEncoding: true,
    });
    let { transcription } = this.props;
    transcription.setBlob(blob);
    const data = await ffmpegConvertToWav(blob);
    const tempBlob = new Blob([data.buffer], {
      type: 'audio/wav',
    });
    const tempUrl = URL.createObjectURL(
      new Blob([data.buffer], { type: 'audio/wav' })
    );
    this.setState({
      isRecording: false,
      audioFile: tempUrl,
      isEncoding: false,
    });
    const file = new File([tempBlob], 'audio.wav', {
      type: 'audio/wav',
    });
    file.preview = tempUrl;
    transcription.setAudio(file);
  };

  handleChangeModel(e) {
    let { transcription } = this.props;
    transcription.setModel(e.target.value);
  }

  startRecording = () => {
    let { transcription } = this.props;
    transcription.resetAudio();
    this.setState({
      isRecording: true,
      isTranscriptionFinished: false,
    });
  };

  showAlertFileRejected = (message) => {
    this.setState({
      fileAlertVisible: true,
      fileAlertMessage: message,
    });
    let timeout = setTimeout(() => {
      this.setState({
        fileAlertVisible: false,
        fileAlertMessage: '',
      });
      clearTimeout(timeout);
    }, 3000);
  };

  setTranscriptionFailed = (shotWaitingTimer, title, message) => {
    this.setState({
      transcriptionAlertTitle: title,
      transcriptionAlertMessage: message,
      isTranscriptionFinished: true,
      isTranscriptionError: true,
      isTranscribing: false,
    });
    if (shotWaitingTimer) {
      this.setState({
        shotWaitingTimer: true,
      });
      let waitingInterval = setInterval(() => {
        let waitingCounter = this.state.waitingCounter - 1;
        this.setState({ waitingCounter: waitingCounter });
      }, 1000);
      let intervalTimeout = setTimeout(() => {
        clearInterval(waitingInterval);
        clearTimeout(intervalTimeout);
        this.setState({
          isTranscriptionError: false,
          isLoading: false,
          isTranscriptionFinished: false,
          shotWaitingTimer: false,
          waitingCounter: this.state.initialCounter,
        });
      }, (this.state.initialCounter + 1) * 1000);
    } else {
      let intervalTimeout = setTimeout(() => {
        clearTimeout(intervalTimeout);
        this.setState({
          isTranscriptionError: false,
          isLoading: false,
          isTranscriptionFinished: false,
        });
      }, 3000);
    }
  };

  render() {
    let { transcription, modelData, remove, duplicate } = this.props;
    return (
      <React.Fragment>
        <CardBody>
          <Row>
            <Col xs='12' md='4' xl='5' className='mb-4 mb-md-0'>
              <Row className='mx-0 mb-3 mb-xl-0'>
                <Col sm='12' xl='6' className='px-0 ml-0 pr-0 pr-xl-4'>
                  <div className='mb-2'>Model</div>
                  <FormGroup>
                    <Input
                      type='select'
                      name='domain'
                      value={transcription.model}
                      onChange={this.handleChangeModel}
                      id='domain-input'
                    >
                      {Object.keys(modelData).map((item) => (
                        <option value={item} key={item}>
                          {modelData[item].label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className='px-0'>
                  <div className='mb-2'>Audio</div>
                  <Row className='mx-0 justify-content-between'>
                    <Col sm='8' className='pl-0 pr-0 pr-md-3 mb-2 mb-md-0'>
                      <RecordingAudio
                        duration={this.state.maxAudioDuration}
                        category='asr'
                        onStartRecording={this.startRecording.bind(this)}
                        onStopRecording={this.setAudioRecordBlob.bind(this)}
                        disabled={this.state.isTranscribing}
                      />
                    </Col>
                    <Col sm='4' className='px-0'>
                      <Uploader
                        maxSize={this.state.maxAudioSize}
                        maxDuration={this.state.maxAudioDuration}
                        model={transcription.model}
                        onAcceptFile={this.setFile}
                        onRejectFile={this.showAlertFileRejected}
                        disabled={
                          this.state.isTranscribing || this.state.isRecording
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                {
                  <Alert color='danger' isOpen={this.state.fileAlertVisible}>
                    {this.state.fileAlertMessage}
                  </Alert>
                }
                {modelData && transcription.model && (
                  <p className='mb-0'>
                    <small>
                      Rekam audio <b>(maks 30 detik)</b> atau unggah audio/video{' '}
                      <b>(wav, mp3, webm, weba, opus, atau mp4)</b> berdurasi{' '}
                      <b>maks 30 detik</b> <b>dan</b> ukuran <b>maks 10 MB</b>.
                      Audio dalam <i>file</i> yang diunggah disarankan memiliki{' '}
                      <i>sample rate</i>{' '}
                      <b>
                        {modelData[transcription.model].samplerate / 1000} kHz
                      </b>
                      .
                    </small>
                  </p>
                )}
              </div>
            </Col>
            <Col>
              {transcription.audio && (
                <>
                  {this.state.isEncoding ? (
                    ''
                  ) : (
                    <>
                      <div className='mb-2'>
                        {transcription.audio.name !== undefined
                          ? transcription.audio.name.split('.')[0] + '.wav'
                          : 'audio.wav'}
                      </div>
                      <Row className='mx-0 mb-4'>
                        <Col
                          sm='12'
                          md='8'
                          xl='8'
                          className='mb-2 mb-md-0 px-0 pr-md-4'
                        >
                          {this.state.audioFile !== null ? (
                            <CustomAudioPlayer src={this.state.audioFile} />
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col className='px-0'>
                          <Row>
                            <Col xl='9'>
                              <Button
                                color='default'
                                onClick={this.transcribeAudio}
                                type='button'
                                disabled={
                                  this.state.isTranscribing ||
                                  this.state.isLoading
                                }
                                id='transcribe-btn'
                                className='mb-2 mb-lg-0 btn-block'
                                style={{ height: '40px' }}
                              >
                                Transkrip
                                {this.state.isTranscribing && (
                                  <span className='ml-2'>
                                    <i
                                      className='fa fa-circle-notch fa-spin text-white'
                                      style={{ fontSize: '16px' }}
                                    ></i>
                                  </span>
                                )}
                              </Button>
                              <UncontrolledTooltip
                                trigger='hover click'
                                placement='top'
                                target='transcribe-btn'
                              >
                                Mulai Transkripsi
                              </UncontrolledTooltip>
                            </Col>
                          </Row>
                        </Col>
                      </Row>{' '}
                    </>
                  )}
                </>
              )}
              {this.state.isEncoding === true &&
              this.state.isRecording === true ? (
                <Row className='align-items-center mt-4'>
                  <Col md={12}>
                    <div className='d-flex justify-content-center align-items-center'>
                      <ScaleLoader
                        height={35}
                        width={4}
                        radius={2}
                        margin={2}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {this.state.isTranscriptionFinished &&
                !this.state.isTranscriptionError &&
                transcription.transcript && (
                  <Transcript transcript={transcription.transcript} xl='11' />
                )}
              <Alert isOpen={this.state.isTranscriptionError} color='danger'>
                <h4 className='text-white'>
                  {this.state.transcriptionAlertTitle}
                </h4>
                <div>
                  {this.state.transcriptionAlertMessage}{' '}
                  <b>
                    {this.state.waitingCounter !== this.state.initialCounter &&
                      this.state.waitingCounter + 's'}
                  </b>
                </div>
              </Alert>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className='text-right'>
          {transcription.audio && (
            <>
              <Button
                color='secondary'
                onClick={this.saveTranscriptResult}
                className='btn-icon btn-2'
                id='download-btn'
                type='button'
              >
                <i className='fas fa-download text-default'></i>
              </Button>
              <UncontrolledTooltip
                trigger='hover'
                placement='top'
                target='download-btn'
              >
                Unduh
              </UncontrolledTooltip>
            </>
          )}
          <Button
            type='button'
            color='secondary'
            className='btn-icon btn-2'
            onClick={duplicate}
            id='duplicate-btn'
          >
            <i className='fa fa-clone'></i>
          </Button>
          <UncontrolledTooltip
            trigger='hover'
            placement='top'
            target='duplicate-btn'
          >
            Duplikasi
          </UncontrolledTooltip>
          <Button
            type='button'
            color='secondary'
            className='btn-icon bnt-2'
            onClick={remove}
            id='remove-btn'
          >
            <i className='fa fa-trash text-danger'></i>
          </Button>
          <UncontrolledTooltip
            trigger='hover'
            placement='top'
            target='remove-btn'
          >
            Hapus
          </UncontrolledTooltip>
        </CardFooter>
      </React.Fragment>
    );
  }
}

export default observer(NonRealTimeTranscription);
